import { Labels } from ".";
import { Respondent } from "../model/respondent";

export const respondentLabels: Labels<Respondent> = {
	_id: "対象者ID",
	jobId: "job",
	name: "対象者氏名",
	mail: "メールアドレス",
	urlParam: "URL",
	mobileTel: "SMS用携帯番号",
	otherInfo: "その他項目"
};
