import * as React from "react";
import { RespondentPage } from "../../components/pages/respondent";
import { reducer, initState } from "../../reducers/respondent";
import { InfoMessage } from "lu-component";
import {
	GetRespondentsResponse,
	CreateRespondentsResponse
} from "../../../../server/types/request/respondent";
import { useParams } from "react-router";
import { get, postFormData, fileDownload, makeError } from "../../lib/request";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";

export const RespondentContainer = () => {
	const { jobId } = useParams();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, respondents } = state;
	React.useEffect(() => {
		get<GetRespondentsResponse>(`/respondents/${jobId}`)
			.then(response => {
				dispatch({
					type: "initRespondents",
					payload: response.data.respondents
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	const onUpload = React.useCallback((file: File) => {
		const formData = new FormData();
		formData.append("respondents", file);
		dispatch({ type: "changeLoading", payload: true });
		postFormData<CreateRespondentsResponse>(`/respondent/${jobId}`, formData)
			.then(response => {
				dispatch({
					type: "setRespondents",
					payload: response.data.respondents
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	const onDownload = React.useCallback((target: "csv" | "QR") => {
		dispatch({ type: "changeLoading", payload: true });
		fileDownload(
			`/respondent/${jobId}/download/${target}`,
			`[${jobId}]対象者${target === "csv" ? "リスト.csv" : "QRコード.zip"}`
		)
			.then(() => {
				dispatch({ type: "changeLoading", payload: false });
			})
			.catch(() => {
				dispatch({
					type: "changeMessageInfo",
					payload: { message: "ダウンロードに失敗しました。", isSuccess: false }
				});
			});
	}, []);
	console.log(info.message);
	return (
		<>
			<InfoMessage info={info} disableAutohide={info && !info.isSuccess} />
			<RespondentPage
				respondents={respondents}
				onUpload={onUpload}
				onDownloadCsv={() => onDownload("csv")}
				onDownloadQRCode={() => onDownload("QR")}
			/>
		</>
	);
};
