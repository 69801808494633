import * as React from "react";
import { Job } from "../../../../../server/model/job";
import { MyPageNavigationBar } from "../../parts/mypage-navigation-bar";
import { Wrapper } from "../mypage";
import { ErrorObject } from "lu-component";
import {
	Row,
	Col,
	FormLabel,
	FormGroup,
	FormControl,
	Card,
	Button,
	Alert
} from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import styled from "styled-components";

const Message = styled.div`
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
`;

export type MyPageLoginProps = {
	errors?: ErrorObject;
	job: Job;
	password: string;
	onChange: (password: string) => void;
	onLogin: () => void;
	message?: string;
};

export const MyPageLogin = React.memo((props: MyPageLoginProps) => {
	const { job, onLogin, errors, password, onChange, message } = props;
	const onChangePassword = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
		[onChange]
	);
	return (
		<Wrapper>
			<div className={"title-area"}>
				<MyPageNavigationBar title={job ? job.infoName : ""} />
			</div>
			<Card className={"m-5 pt-5 pb-5"}>
				<Card.Body>
					<div className={"main-area"}>
						<div className="justify-content-md-center">
							{!!message && <Alert variant={"danger"}>{message}</Alert>}
							<Message>案内されているパスワードを入力してください。</Message>
						</div>
						<FormGroup as={Row} className="justify-content-md-center">
							<FormLabel column sm={2}>
								password
							</FormLabel>
							<Col sm={6}>
								<FormControl
									type="password"
									placeholder="Password"
									onChange={onChangePassword}
									isInvalid={!!password && errors && "password" in errors}
								/>
								{errors && "password" in errors && (
									<Feedback type={"invalid"}>{errors["password"]}</Feedback>
								)}
							</Col>
						</FormGroup>
						<Row className="justify-content-md-center">
							<Col sm={4}>
								<Button block disabled={!password} onClick={onLogin}>
									Login
								</Button>
							</Col>
						</Row>
					</div>
				</Card.Body>
			</Card>
		</Wrapper>
	);
});
