import { MyPageAction } from "../types";
import { MessageInfo } from "../types/info";
import { Plan } from "../../../server/model/plan";
import { Job } from "../../../server/model/job";
import { SurveyWithAnswer } from "../../../server/model/survey";
import { Chat } from "../../../server/model/chat";

export type State = {
	job: Job;
	surveys: SurveyWithAnswer[];
	plans: Plan[];
	chats: Chat[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	job: undefined,
	surveys: [],
	plans: [],
	chats: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: MyPageAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		case "setInit":
			return {
				...state,
				...action.payload,
				loading: false,
				info: { message: "", isSuccess: true }
			};
		default:
			return state;
	}
};
