import * as React from "react";
import { Chat } from "../../../../../../server/model/chat";
import { Alert, Badge } from "react-bootstrap";
import { ConfirmButton } from "lu-component";

export type ChatHistoryProps = {
	onChatDelete: (respondentId: string) => void;
} & Chat;

export const ChatHistory = React.memo((props: ChatHistoryProps) => {
	const { onChatDelete, ...chatItem } = props;
	return (
		<Alert
			style={{
				padding: "0px",
				width: "150px",
				whiteSpace: "pre-wrap",
				wordWrap: "break-word"
			}}
			variant={
				chatItem.fromAdmin
					? "info"
					: chatItem.openDatetime === ""
					? "danger"
					: "warning"
			}
		>
			{chatItem.fromAdmin && (
				<span style={{ float: "right" }}>
					<ConfirmButton
						type={"times-circle"}
						buttonText={"削除"}
						title={"やりとりの削除"}
						body={"削除すると送信した履歴が消えますがよろしいですか？"}
						onOk={() => onChatDelete(chatItem._id.toString())}
					/>
				</span>
			)}
			<Badge variant="secondary">{chatItem.sendDatetime}</Badge>
			<br />
			{chatItem.message}
		</Alert>
	);
});
