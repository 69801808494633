import { SurveyAction } from "../types";
import { MessageInfo } from "../types/info";
import { Option } from "lu-component";
import { SurveyWithReminder } from "../../../server/model/survey";
import { PlanWithReminder } from "../../../server/model/plan";
import { Job } from "../../../server/model/job";

export type State = {
	job: Job;
	surveys: SurveyWithReminder[];
	plans: PlanWithReminder[];
	jobEnquetes: Option[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	job: undefined,
	surveys: [],
	plans: [],
	jobEnquetes: [],
	loading: false,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: SurveyAction): State => {
	switch (action.type) {
		case "setSurveysAndPlansAndOptions":
			return {
				...state,
				job: action.payload.job,
				surveys: action.payload.surveys,
				plans: action.payload.plans,
				jobEnquetes: action.payload.options
			};
		case "addSurvey":
			return {
				...state,
				surveys: state.surveys.concat(action.payload),
				info: { message: "調査の登録が完了しました。", isSuccess: true }
			};
		case "changeSurvey":
			return {
				...state,
				surveys: state.surveys.map(survey =>
					survey._id === action.payload._id ? action.payload : survey
				),
				info: { message: "調査の更新が完了しました。", isSuccess: true }
			};
		case "deleteSurvey":
			return {
				...state,
				surveys: state.surveys.filter(survey => survey._id !== action.payload),
				info: { message: "調査の削除が完了しました。", isSuccess: true }
			};
		case "addPlan":
			return {
				...state,
				plans: state.plans.concat(action.payload),
				info: { message: "予定の登録が完了しました。", isSuccess: true }
			};
		case "changePlan":
			return {
				...state,
				plans: state.plans.map(plan =>
					plan._id === action.payload._id ? action.payload : plan
				),
				info: { message: "予定の更新が完了しました。", isSuccess: true }
			};
		case "deletePlan":
			return {
				...state,
				plans: state.plans.filter(plan => plan._id !== action.payload),
				info: { message: "予定の削除が完了しました。", isSuccess: true }
			};
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
	}
};
