import * as React from "react";
import { get, remove, fileDownload, makeError, post } from "../../lib/request";
import { InfoMessage } from "lu-component";
import { useHistory, useParams } from "react-router";

import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";
import { JobEnquetePage } from "../../components/pages/job-enquete";
import {
	GetJobEnqueteResponse,
	ClearJobEnqueteResponse,
	CopyJobEnqueteResponse
} from "../../../../server/types/request/job-enquete";
import { reducer, initState } from "../../reducers/job-enquete";

export const JobEnqueteContainter = () => {
	const history = useHistory();
	const { jobId } = useParams();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { jobEnquetes, info } = state;
	React.useEffect(() => {
		get<GetJobEnqueteResponse>(`/job-enquetes/${jobId}`)
			.then(response => {
				dispatch({
					type: "setJobEnquetes",
					payload: response.data.jobEnquetes
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onEditPage = React.useCallback((id?: string) => {
		history.push(`/enquete/${jobId}/${id || "new"}`);
	}, []);
	const onClear = React.useCallback((id: string) => {
		remove<ClearJobEnqueteResponse>("/job-enquete/clear", id)
			.then(response => {
				dispatch({
					type: "clearJobEnquete",
					payload: response.data.jobEnquete
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onCopy = React.useCallback((id: string) => {
		post<CopyJobEnqueteResponse>("/copy-enquete", { jobEnqueteId: id })
			.then(response => {
				dispatch({
					type: "copyJobEnquete",
					payload: { id, jobEnquete: response.data.jobEnquete }
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onDelete = React.useCallback((id: string) => {
		remove<{}>("/job-enquete", id)
			.then(() => {
				dispatch({ type: "deleteJobEnquete", payload: id });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: { ...error.response.data, isSuccess: false }
				});
			});
	}, []);
	const onDownload = React.useCallback((id: string) => {
		fileDownload(`/dl/enquete/${id}`, `${id}_回答データ.csv`).catch(() => {
			dispatch({
				type: "changeMessageInfo",
				payload: { isSuccess: false, message: "ダウンロードに失敗しました。" }
			});
		});
	}, []);
	const onUploadFileDownload = React.useCallback((id: string) => {
		fileDownload(
			`/dl_file/enquete/${id}`,
			`${id}_アップロードデータ.zip`
		).catch(() => {
			dispatch({
				type: "changeMessageInfo",
				payload: {
					isSuccess: false,
					message:
						"ダウンロードに失敗しました。対象のファイルがない可能性があります。"
				}
			});
		});
	}, []);
	const onUploadLayoutDownload = React.useCallback((id: string) => {
		fileDownload(
			`/dl_layout/enquete/${id}`,
			`${id}_レイアウトファイル.csv`
		).catch(() => {
			dispatch({
				type: "changeMessageInfo",
				payload: {
					isSuccess: false,
					message:
						"ダウンロードに失敗しました。対象のファイルがない可能性があります。"
				}
			});
		});
	}, []);
	return (
		<>
			<InfoMessage info={info} />
			<JobEnquetePage
				onDownload={onDownload}
				onUploadFileDownload={onUploadFileDownload}
				onUploadLayoutDownload={onUploadLayoutDownload}
				jobEnquetes={jobEnquetes}
				onEditPage={onEditPage}
				onDelete={onDelete}
				onClear={onClear}
				onCopy={onCopy}
			/>
		</>
	);
};
