import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

export type CautionProps = {
	show: boolean;
	title?: string;
	message: string;
};
export const Caution = React.memo((props: CautionProps) => {
	const { show, title, message } = props;
	return (
		<Modal show={show}>
			<Modal.Header>
				<div style={{ textAlign: "left" }}>
					<FontAwesomeIcon icon={faExclamationCircle} color={"red"} />
					<span
						style={{ fontWeight: "bold", color: "red", marginLeft: "10px" }}
					>
						{title || "エラー"}
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ whiteSpace: "pre-wrap" }}>{message}</div>
			</Modal.Body>
		</Modal>
	);
});
