import * as React from "react";
import { Navbar, Card, Button } from "react-bootstrap";

export type LogoutPageProps = {
	changeLocation: () => void;
};
export const LogoutPage = (props: LogoutPageProps) => {
	return (
		<>
			<Navbar bg={"primary"} variant={"dark"}>
				<Navbar.Brand>NI-HUT-DX</Navbar.Brand>
			</Navbar>
			<Card className={"m-5 pt-5 pb-5"}>
				<Card.Body>
					<div className="text-center">
						ログアウトしました。
						<br />
						再度ログインする場合は、ログインページからアクセスしてください。
						<br />
						<br />
						<Button variant={"secondary"} onClick={props.changeLocation}>
							ログインページへ
						</Button>
					</div>
				</Card.Body>
			</Card>
		</>
	);
};
