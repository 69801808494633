import { JobEnquete, JobEnqueteItem } from "../model/job-enquete";
import { Labels } from ".";

export const jobEnqueteLabels: Labels<JobEnquete> = {
	_id: "アンケート",
	jobId: "job",
	muscatSurveyId: "muscatアンケートID"
};

export const jobEnqueteItemLabels: Labels<JobEnqueteItem> = {
	...jobEnqueteLabels,
	enqueteName: "アンケートタイトル",
	status: "ステータス",
	ansConunt: "回収状況"
};
