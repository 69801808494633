import * as React from "react";
import { ChatPage } from "../../components/pages/chat";
import { reducer, initState } from "../../reducers/chat";
import { Caution } from "../../components/parts/caution";
import { useHistory } from "react-router";
import { get, post, makeError } from "../../lib/request";
import {
	GetChatResponse,
	CreateChatResponse
} from "../../../../server/types/request/chat";
import { AxiosError } from "axios";
import { InfoMessage } from "lu-component";
import { ErrorResponse } from "../../../../server/types/request";

export const ChatContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, chats, job } = state;
	const history = useHistory();
	const onMyPage = React.useCallback(() => history.push(`/mypage`), []);
	React.useEffect(() => {
		// ieはリクエストのgetもキャッシュされるので、その対応
		get<GetChatResponse>("/chats", { a: Math.random() })
			.then(response => {
				dispatch({ type: "setInit", payload: response.data });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	const onSend = React.useCallback(
		(message: string) => {
			post<CreateChatResponse>("/chat", { message })
				.then(response => {
					dispatch({
						type: "addChat",
						payload: response.data.chat
					});
					setTimeout(() => {
						dispatch({
							type: "addAutoReply",
							payload: response.data.chat
						});
					}, 1500);
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error, true)
					});
				});
		},
		[chats]
	);
	return (
		<>
			{loading && !info.isSuccess && <Caution show message={info.message} />}
			{!loading && <InfoMessage info={info} />}
			<ChatPage chats={chats} job={job} onSend={onSend} onMyPage={onMyPage} />
		</>
	);
};
