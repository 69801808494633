import * as React from "react";
import { JobEnqueteItem } from "../../../../../server/model/job-enquete";
import { Column, ListTable, EventItem } from "lu-component";
import { enqueteStatusOption } from "muscat-library";
import { Row, Col, Button } from "react-bootstrap";
import { jobEnqueteItemLabels } from "../../../../../server/label/job-enquete";

export type JobEnquetePageProps = {
	onEditPage: (id?: string) => void;
	onDelete: (id: string) => void;
	onClear: (id: string) => void;
	onDownload: (id: string) => void;
	onUploadFileDownload: (id: string) => void;
	onUploadLayoutDownload: (id: string) => void;
	onCopy: (id: string) => void;
	jobEnquetes: JobEnqueteItem[];
};
const columns: Column<JobEnqueteItem>[][] = [
	[
		{
			name: "muscatSurveyId",
			label: jobEnqueteItemLabels.muscatSurveyId,
			type: "readonly"
		}
	],
	[
		{
			name: "enqueteName",
			label: jobEnqueteItemLabels.enqueteName,
			type: "readonly"
		}
	],
	[
		{
			name: "ansConunt",
			label: jobEnqueteItemLabels.ansConunt,
			type: "readonly"
		}
	],
	[
		{
			name: "status",
			label: jobEnqueteItemLabels.status,
			type: "select",
			options: enqueteStatusOption
		}
	]
];
export const JobEnquetePage = React.memo((props: JobEnquetePageProps) => {
	const {
		onEditPage,
		jobEnquetes,
		onDelete,
		onClear,
		onDownload,
		onCopy,
		onUploadFileDownload,
		onUploadLayoutDownload
	} = props;
	const events: EventItem[] = React.useMemo(
		() => [
			{
				text: "編集",
				variant: "outline-success",
				action: onEditPage,
				type: "custom"
			},
			{
				type: "delete",
				text: "削除",
				action: onDelete
			},
			{
				type: "delete",
				variant: "outline-warning",
				text: "データクリア",
				action: onClear
			},
			{
				text: "コピー",
				variant: "outline-dark",
				action: onCopy,
				type: "custom"
			},
			{
				type: "pulldown",
				text: "ダウンロード",
				variant: "outline-secondary",
				actions: [
					{ text: "ローデータ", action: onDownload },
					{ text: "アップロードファイル", action: onUploadFileDownload },
					{ text: "レイアウトファイル", action: onUploadLayoutDownload }
				]
			}
		],
		[onEditPage]
	);
	return (
		<>
			<Row>
				<Col md={12}>
					<div className={"h3"}>■ アンケート一覧</div>
				</Col>

				<Col md={{ offset: 10, span: 2 }}>
					<div className={"m-3"}>
						<Button onClick={() => onEditPage()}>新規作成</Button>
					</div>
				</Col>
			</Row>
			<ListTable data={jobEnquetes} columns={columns} events={events} />
		</>
	);
});
