import * as React from "react";
import { MyPageNavigationBar } from "../../parts/mypage-navigation-bar";
import { Wrapper } from "../mypage";
import { Card } from "react-bootstrap";
import styled from "styled-components";

export type MyPageErrorProps = {
	title: string;
	message: React.ReactNode | React.ReactElement;
};

export const MyPageErrorPage = React.memo((props: MyPageErrorProps) => {
	const { title, message } = props;
	return (
		<Wrapper>
			<div className={"title-area"}>
				<MyPageNavigationBar title={title} />
			</div>
			<div className={"main-area"}>
				<Card className={"m-5 pt-5 pb-5"}>
					<Card.Body>
						<div className="justify-content-md-center">{message}</div>
					</Card.Body>
				</Card>
			</div>
		</Wrapper>
	);
});
