import * as React from "react";
import {
	JobItem,
	Job,
	jobStatusOptions
} from "../../../../../server/model/job";
import {
	Column,
	Option,
	CustomForm,
	EditForm,
	ListTable,
	EventItem
} from "lu-component";
import { Card, Row, Col } from "react-bootstrap";
import { jobItemLabel, jobLabels } from "../../../../../server/label/job";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import { CopyJobForm } from "../../parts/copy-job";
import styled from "styled-components";

const FontRed = styled.span`
	color: red;
`;

const TableWrap = styled.div`
	thead tr th {
		min-width: 100px;
		max-width: 250px;
	}
	thead tr th div {
		max-width: 250px;
	}
	thead tr th:nth-child(n+8), tbody tr td:nth-child(n+8) {
		min-width: fit-content;
		padding-right: 0;
	}
	thead tr th:nth-child(n+9), tbody tr td:nth-child(n+9) {
		padding-left: 0;
	}
	tbody tr td button {
		white-space: nowrap;
		margin: 3px;
	}
	td div.wrap-area {
		max-width: 250px;
	}
`;

const columns: Column<JobItem>[][] = [
	[
		{ name: "jobNo", label: jobItemLabel.jobNo, type: "text" },
		{ name: "name", label: jobItemLabel.name, type: "text" }
	],
	[{ name: "infoName", label: jobItemLabel.infoName, type: "text" }],
	[
		{
			name: "accountId",
			label: jobItemLabel.accountId,
			type: "select",
			options: [],
			col: 5
		},
		{
			name: "clientName",
			label: jobItemLabel.clientName,
			type: "text",
			col: 5
		}
	],
	[
		{
			name: "status",
			label: jobItemLabel.status,
			type: "select",
			options: jobStatusOptions
		}
	],
	[
		{
			name: "board",
			label: (
				<>
					{jobItemLabel.board}
					<br />
					※モニターのマイページに表示されます
				</>
			),
			type: "textarea"
		}
	],
	[
		{
			name: "board2",
			label: `${jobItemLabel.board2}`,
			type: "textarea"
		}
	],
	[
		{
			name: "answerdCountDisplay",
			label: jobItemLabel.answerdCountDisplay,
			type: "readonly"
		}
	],
	[{ name: "password", label: jobItemLabel.password, type: "text", hide: true }]
];

export type JobPageProps = {
	jobs: JobItem[];
	accounts: Option[];
	accountsWithoutDeleted: Option[];
	search: Partial<Job>;
	onSearch: (job: Partial<Job>) => void;
	onUpsert: (job: Job, cb?: () => void) => void;
	onCopy: (job: Job, cb?: () => void) => void;

	onCloseEvent?: () => void;
	onDelete: (id: string) => void;
	onEnquetePage: (id: string) => void;
	onDownload: (id: string) => void;
	onEnqueteManagePage: (id: string) => void;
	onRespondentPage: (id: string) => void;
	onChatPage: (id: string) => void;

	errors?: ErrorObject;
};
export const JobPage = React.memo((props: JobPageProps) => {
	const {
		jobs,
		accounts,
		accountsWithoutDeleted,
		search,
		onSearch,
		onUpsert,
		onDelete,
		onCloseEvent,
		onEnquetePage,
		onEnqueteManagePage,
		onDownload,
		onRespondentPage,
		onChatPage,
		onCopy,
		errors
	} = props;
	const jobColumns = React.useMemo(() => {
		return columns.map(cols =>
			cols.map(col =>
				col.name === "accountId" ? { ...col, options: accounts } : col
			)
		);
	}, [accounts]);
	const jobColumnsWithoutDeletedAccounts = React.useMemo(() => {
		return columns.map(cols =>
			cols.map(col =>
				col.name === "accountId" ? { ...col, options: accountsWithoutDeleted } : col
			)
		);
	}, [accountsWithoutDeleted]);
	const editColumns: Column[][] = React.useMemo(
		() => [
			[
				{
					name: "edit",
					label: "編集",
					type: "custom",
					isShow: () => false,
					view: (job: Job) => (
						<EditForm
							doUpdate={true}
							columns={jobColumnsWithoutDeletedAccounts}
							onSubmit={onUpsert}
							onCloseEvent={onCloseEvent}
							errors={errors}
							data={job}
						/>
					)
				}
			]
		],
		[onUpsert, onCloseEvent, jobColumnsWithoutDeletedAccounts]
	);
	const events: EventItem[] = React.useMemo(
		() => [
			{
				text: "削除",
				variant: "outline-danger",
				type: "delete",
				action: onDelete
			},
			{
				type: "pulldown",
				actions: [
					{
						text: "アンケート一覧",
						action: onEnquetePage,
						type: "custom"
					},
					{
						text: "スケジュール登録",
						action: onEnqueteManagePage,
						type: "custom"
					},
					{
						text: "対象者設定",
						action: onRespondentPage,
						type: "custom"
					},
					{
						text: "チャット管理",
						action: onChatPage,
						type: "custom"
					}
				]
			}
		],
		[onUpsert, onDelete, onEnquetePage, onDownload]
	);
	const copyColumns: Column[][] = React.useMemo(
		() => [
			[
				{
					name: "copy",
					label: "コピー",
					type: "custom",
					isShow: () => false,
					view: (job: Job) => (
						<CopyJobForm
							columns={jobColumnsWithoutDeletedAccounts.map(cols =>
								cols.map(col => {
									if (
										col.name === "jobNo" ||
										col.name === "name" ||
										col.name === "infoName"
									) {
										return {
											...col,
											label: <FontRed>{col.label}</FontRed>
										};
									}
									return col;
								})
							)}
							job={job}
							onCopy={onCopy}
							errors={errors}
						/>
					)
				}
			]
		],
		[onCopy, jobColumnsWithoutDeletedAccounts]
	);
	const searchColumns = React.useMemo(
		() =>
			[
				[
					{ name: "name", type: "text", label: jobLabels.name, col: 3 },
					{
						name: "status",
						type: "select",
						label: jobLabels.status,
						options: jobStatusOptions,
						col: 3
					},
					{
						name: "accountId",
						type: "select",
						label: jobLabels.accountId,
						// 削除済みのアカウントは検索時に表示させない
						options: accountsWithoutDeleted,
						col: 3
					}
				],
				[
					{
						name: "clientName",
						label: jobItemLabel.clientName,
						type: "text",
						col: 3
					}
					/*/
					{
						name: "jobNo",
						label: `${jobItemLabel.jobNo}並び順`,
						col: 3,
						type: "select",
						options: [
							{ value: "asc", label: "昇順" },
							{ value: "desc", label: "降順" }
						]
					}
					/*/
				]
			] as Column<Job>[][],
		[accounts]
	);
	const tableColumns: Column[][] = React.useMemo(
		() => [
			...jobColumns,
			...editColumns,
			[{ name: "event", label: "", type: "events", events }],
			...copyColumns
		],
		[jobColumns, editColumns, events, copyColumns]
	);
	return (
		<>
			<Row>
				<Col md={12}>
					<div className={"h3"}>■ ジョブ一覧</div>
				</Col>
				<Col md={12}>
					<Card>
						<Card.Header>ジョブ一覧検索</Card.Header>
						<Card.Body>
							<CustomForm
								columns={searchColumns}
								onSubmit={onSearch}
								buttonText={"Search"}
								data={search}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col md={{ offset: 10, span: 2 }}>
					<div className={"m-3"}>
						<EditForm
							columns={jobColumnsWithoutDeletedAccounts}
							onSubmit={onUpsert}
							onCloseEvent={onCloseEvent}
							errors={errors}
							data={{ status: "ongoing" }} // 初期値をセット
						/>
					</div>
				</Col>
			</Row>
			<TableWrap>
				<ListTable data={jobs} columns={tableColumns} errors={errors} />
			</TableWrap>
		</>
	);
});
