import { ChatAdminAction } from "../types";
import { MessageInfo } from "../types/info";
import { Chat } from "../../../server/model/chat";
import { RespondentWithRealId } from "../../../server/model/respondent";

export type State = {
	respondents: RespondentWithRealId[];
	chatText: { [respondentId: string]: string };
	expandText: string;
	chats: Chat[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	respondents: [],
	chats: [],
	expandText: "",
	chatText: {},
	loading: false,
	info: {
		message: "",
		isSuccess: true
	}
};

const clearChatText = (
	chatText: { [respondentId: string]: string },
	chats: Chat[]
) => {
	const tmp = { ...chatText };
	chats.forEach(chat => {
		if (tmp[chat.respondentId]) tmp[chat.respondentId] = "";
	});
	return tmp;
};
const expandChatText = (
	targetRespondentId: string[],
	chatText: { [respondentId: string]: string },
	expandText: string
) => {
	const tmp = { ...chatText };
	targetRespondentId.forEach(respondentId => {
		tmp[respondentId] = expandText;
	});
	return tmp;
};
export const reducer = (state: State, action: ChatAdminAction): State => {
	switch (action.type) {
		case "setRespondentsAndChats":
			return {
				...state,
				...action.payload,
				loading: false,
				info: { message: "", isSuccess: true }
			};
		case "expandChatText":
			return {
				...state,
				chatText: expandChatText(
					action.payload,
					state.chatText,
					state.expandText
				)
			};
		case "changeChatText":
			return {
				...state,
				chatText: {
					...state.chatText,
					[action.payload.respondentId]: action.payload.value
				}
			};
		case "changeChatTexts":
			return {
				...state,
				chatText: action.payload
			};
		case "deleteChat":
			return {
				...state,
				chats: state.chats.filter(c => c._id !== action.payload),
				info: { message: "チャットの削除が完了しました。", isSuccess: true }
			};
		case "addChats":
			return {
				...state,
				chats: state.chats.concat(action.payload),
				chatText: clearChatText(state.chatText, action.payload),
				info: { message: "チャットの追加が完了しました。", isSuccess: true }
			};

		case "changeExpandText":
			return {
				...state,
				expandText: action.payload
			};
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		default:
			return state;
	}
};
