import { RespondentAction } from "../types";
import { MessageInfo } from "../types/info";
import { Respondent } from "../../../server/model/respondent";

export type State = {
	respondents: Respondent[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	respondents: [],
	loading: false,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: RespondentAction): State => {
	switch (action.type) {
		case "initRespondents":
			return { ...state, respondents: action.payload };
		case "setRespondents":
			return {
				...state,
				respondents: action.payload,
				info: {
					message: `対象者の${
						state.respondents.length ? "更新" : "登録"
					}が完了しました。`,
					isSuccess: true
				},
				loading: false
			};
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		default:
			return state;
	}
};
