import * as React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export type SendFormProps = {
	respondentId: string;
	onSend: (respondentId: string, value: string) => void;
	onChange: (respondentId: string, value: string) => void;
	value: string;
};
const TextArea = React.memo(
	(props: {
		onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
		value: string;
	}) => {
		const { onChange, value } = props;
		return (
			<textarea
				onChange={onChange}
				style={{
					width: "150px",
					border: "1px solid #ddd",
					height: "100%"
				}}
				value={value}
			/>
		);
	}
);
export const SendForm = React.memo((props: SendFormProps) => {
	const { respondentId, onSend, value, onChange } = props;
	const onSendClick = React.useCallback(() => {
		onSend(respondentId, value);
	}, [respondentId, onSend, value]);
	const onChangeValue = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			onChange(respondentId, e.target.value);
		},
		[onChange, respondentId]
	);
	return (
		<>
			<table>
				<tbody>
					<tr>
						<td style={{ padding: "0px", border: "0px" }}>
							<TextArea onChange={onChangeValue} value={value} />
						</td>
						<td
							style={{
								padding: "0px",
								border: "0px",
								verticalAlign: "middle"
							}}
						>
							<Button
								style={{
									height: "100%",
									paddingLeft: "2px",
									paddingRight: "2px"
								}}
								variant="success"
								onClick={onSendClick}
								disabled={!value}
							>
								<FontAwesomeIcon icon={faPaperPlane} />
							</Button>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
});
