/* eslint-disable @typescript-eslint/camelcase */
import { EnqueteAction } from "../types";
import { MessageInfo } from "../types/info";
import { Enquete } from "muscat-library";

export type State = {
	id: string;
	enquete?: Enquete;
	loading: boolean;
	info: MessageInfo;
	previewURL: string;
	enqueteURL: string;
};

export const initState: State = {
	id: "new",
	enquete: undefined,
	loading: true,
	info: {
		message: "",
		isSuccess: true
	},
	previewURL: "",
	enqueteURL: ""
};

export const reducer = (state: State, action: EnqueteAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		case "setEnquete":
			return {
				...state,
				loading: false,
				...action.payload
			};
		default:
			return state;
	}
};
