/* eslint-disable @typescript-eslint/camelcase */
import * as React from "react";
import { QuestionMaker, Loading, InfoMessage } from "lu-component";
import { reducer, initState } from "../../reducers/enquete";
import { useParams } from "react-router";
import { get, put, post, makeError } from "../../lib/request";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorResponse } from "../../../../server/types/request";
import { Enquete } from "muscat-library";
import {
	GetEnqueteResponse,
	UpsertEnqueteResponse
} from "../../../../server/types/request/enquete";
import { copyText } from "../../lib/clipboad";

export const EnqueteContainer = () => {
	const { jobEnqueteId, jobId } = useParams();
	const [state, dispatch] = React.useReducer(reducer, {
		...initState,
		id: jobEnqueteId
	});
	const { info, loading, previewURL, enqueteURL, enquete, id } = state;
	React.useEffect(() => {
		let request: Promise<AxiosResponse<GetEnqueteResponse>>;
		if (jobEnqueteId === "new") {
			request = get("/init-enquetes");
		} else {
			request = get(`/enquetes/${jobEnqueteId}`);
		}
		request
			.then(response => {
				dispatch({ type: "setEnquete", payload: response.data });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onSave = React.useCallback(
		(
			enquete: Enquete,
			changePreview?: (response: UpsertEnqueteResponse) => void
		) => {
			let request: Promise<AxiosResponse<UpsertEnqueteResponse>>;
			dispatch({ type: "changeLoading", payload: false });
			if (id !== "new") {
				request = put(`/enquete/${id}`, enquete);
			} else {
				request = post(`/enquete/${jobId}`, enquete);
			}
			request
				.then(response => {
					dispatch({
						type: "setEnquete",
						payload: response.data
					});
					dispatch({
						type: "changeMessageInfo",
						payload: {
							isSuccess: true,
							message: `アンケートの${
								jobEnqueteId === "new" ? "登録" : "更新"
							}が完了しました。`
						}
					});
					if (changePreview && typeof changePreview === "function") {
						changePreview(response.data);
					}
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[id]
	);
	const onPreview = React.useCallback(
		(newEnquete: Enquete) => {
			// すでにpreviewURLが発行されていて、値が一致であれば更新処理は不要。
			if (
				previewURL &&
				JSON.stringify(newEnquete) === JSON.stringify(enquete)
			) {
				return window.open(previewURL);
			}
			onSave(newEnquete, (response: { previewURL: string }) => {
				window.open(response.previewURL);
			});
		},
		[onSave, previewURL, enquete]
	);
	const onDebug = React.useCallback(
		(newEnquete: Enquete) => {
			// すでにpreviewURLが発行されていて、値が一致であれば更新処理は不要。
			if (
				enqueteURL &&
				JSON.stringify(newEnquete) === JSON.stringify(enquete)
			) {
				return window.open(enqueteURL);
			}
			onSave(newEnquete, (response: { enqueteURL: string }) => {
				window.open(response.enqueteURL);
			});
		},
		[onSave, enqueteURL, enquete]
	);
	const onURLCopy = React.useCallback(
		(newEnquete: Enquete) => {
			// すでにpreviewURLが発行されていて、値が一致であれば更新処理は不要。
			if (
				enqueteURL &&
				JSON.stringify(newEnquete) === JSON.stringify(enquete)
			) {
				copyText(enqueteURL);
				dispatch({
					type: "changeMessageInfo",
					payload: {
						isSuccess: true,
						message: "クリップボードにコピーしました"
					}
				});
			}
			onSave(newEnquete, (response: { enqueteURL: string }) => {
				copyText(response.enqueteURL);
				dispatch({
					type: "changeMessageInfo",
					payload: {
						isSuccess: true,
						message: "クリップボードにコピーしました"
					}
				});
			});
		},
		[onSave, enqueteURL, enquete]
	);
	return (
		<>
			<InfoMessage info={info} disableAutohide={info && !info.isSuccess} />
			<Loading loading={loading} />
			<QuestionMaker
				onSave={onSave}
				enquete={state.enquete}
				onPreview={onPreview}
				onDebug={onDebug}
				onURLCopy={onURLCopy}
			/>
		</>
	);
};
