import * as React from "react";
import { SurveyWithAnswer } from "../../../../../server/model/survey";
import { Job } from "../../../../../server/model/job";
import styled from "styled-components";
import {
	PostMessageClickCalendarPlan,
	CalendarPlan
} from "../../../fullcalendar/types";
import { Chat } from "../../../../../server/model/chat";
import { Plan } from "../../../../../server/model/plan";
import { MyPageNavigationBar } from "../../parts/mypage-navigation-bar";
const moment = require("moment");
import { Precedent } from "../../parts/precedent";
import { labels } from "../../../lib/label";
import { Alert } from "react-bootstrap";

export const BoardViewArea = styled.div`
	padding: 10px;
	white-space: pre-wrap;
	word-break: break-all;
	margin: 3px 0px 10px 0px;
	border-bottom: 1px solid #ddd;
	font-size: 0.95rem;
`;
const CanAnswerArea = styled.div`
	margin: 3px 0px 10px 0px;
	padding: 10px 0px;
	color: red;
	font-weight: bold;
`;
const CanAnswerItem = styled.div``;
export const Wrapper = styled.div`
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI",
		"メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

	div.title-area {
		background-color: #2b6ad6;
	}
	div.main-area {
		max-width: 1000px;
		margin: 0px auto;
	}
	.navbar {
		max-width: 1000px;
		margin: 0px auto;
	}
`;
export const NavWrap = styled.div`
	height: 55px;
	& .navbar {
		background-color: #2b6ad6;
	}
	& .navbar-brand {
		padding: 3px 0px;
		font-size: 1.2rem;
	}
`;
export const Badge = styled.div`
	position: absolute;
	right: 8px;
	border-radius: 14px;
	width: 14px;
	height: 14px;
	background-color: red;
	text-align: center;
	font-size: 10px;
	color: white;
`;

export type MyPageProps = {
	job: Job;
	surveys: SurveyWithAnswer[];
	plans: Plan[];
	chats: Chat[];
	onChatPage: () => void;
};

export const MyPage = React.memo((props: MyPageProps) => {
	const { job, surveys, plans, onChatPage, chats } = props;
	const [targetId, setTargetId] = React.useState<string>(undefined);
	React.useEffect(() => {
		if (!targetId) return;
		const survey = surveys.find(survey => survey._id === targetId);
		if (survey && survey.enableAnswer) {
			location.href = survey.url;
		}
	}, [targetId]);
	const unopenedMessage = React.useMemo(() => {
		if (!chats) return 0;
		return chats.reduce((a, b) => {
			if (b.fromAdmin && b.openDatetime === "") {
				return a + 1;
			}
			return a;
		}, 0);
	}, [chats]);
	const filteredCanAnswerSurvey = React.useMemo(() => {
		return surveys.filter(survey => {
			return survey.enableAnswer;
		});
	}, [surveys]);
	const filteredPlan = React.useMemo(() => {
		return plans.filter(plan => {
			const now = moment();
			const start = moment(plan.startDatetime);
			const end = moment(plan.endDatetime);
			return now.isBetween(start, end);
		});
	}, [plans]);
	const ref = React.createRef<HTMLIFrameElement>();

	React.useEffect(() => {
		const origin = process.env.APP_URL;
		if (ref.current) {
			ref.current.onload = () => {
				window.addEventListener("message", ev => {
					if (ev.origin !== origin) return false;
					if (!ev.data || ev.data.type !== "onClickCadendarPlan") return;
					const data: PostMessageClickCalendarPlan = ev.data;
					setTargetId(data.payload);
				});
			};
		}
	}, [ref]);
	React.useEffect(() => {
		const calendarPlan: CalendarPlan[] = [];
		for (const s of surveys) {
			calendarPlan.push({
				_id: s._id.toString(),
				name: s.name,
				startDatetime: s.startDatetime,
				endDatetime: s.endDatetime,
				bgColor: s.enableAnswer ? "#c0ca33" : "#ddd",
				enableAnswer: s.enableAnswer || false,
				color: s.enableAnswer ? "white" : "#555"
			});
		}
		for (const p of plans) {
			calendarPlan.push({
				_id: p._id.toString(),
				name: p.title,
				startDatetime: p.startDatetime,
				endDatetime: p.endDatetime,
				bgColor: p.type === "dueDate" ? "#ffa8a8" : "#7986cb",
				enableAnswer: false
			});
		}
		setTimeout(() => {
			if (ref.current && ref.current.contentWindow) {
				ref.current.contentWindow.postMessage(
					{
						type: "loadCalendarPlan",
						payload: calendarPlan
					},
					"*"
				);
			}
		}, 500);
	}, [ref, surveys, plans]);
	return (
		<Wrapper>
			<div className={"title-area"}>
				<MyPageNavigationBar
					title={job ? job.infoName : ""}
					onChatPage={onChatPage}
					unopenedMessage={unopenedMessage}
				/>
			</div>
			<div className={"main-area"}>
				{job && job.board && (
					<BoardViewArea dangerouslySetInnerHTML={{ __html: job.board }} />
				)}
				{job && job.board2 && (
					<BoardViewArea dangerouslySetInnerHTML={{ __html: job.board2 }} />
				)}
				{(filteredCanAnswerSurvey.length > 0 || filteredPlan.length > 0) && (
					<CanAnswerArea>
						{filteredCanAnswerSurvey.map((survey, index) => {
							return (
								<CanAnswerItem key={`survey-${index}`}>
									「{survey.name}」のアンケートを回答してください。
								</CanAnswerItem>
							);
						})}
						{filteredPlan.map((plan, index) => {
							return (
								<CanAnswerItem key={`plan-${index}`}>
									「{plan.title}」の期間中です。
								</CanAnswerItem>
							);
						})}
					</CanAnswerArea>
				)}
				<Precedent labelGroups={labels} />
				<div style={{ textAlign: "center" }}>
					<iframe
						src="/child-iframe.html"
						style={{ width: "100%", height: "550px" }}
						ref={ref}
					/>
				</div>
			</div>
		</Wrapper>
	);
});
