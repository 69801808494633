import { ChatAction } from "../types";
import { MessageInfo } from "../types/info";
import { Chat } from "../../../server/model/chat";
import { Job } from "../../../server/model/job";

export type State = {
	job: Job;
	chats: Chat[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	job: undefined,
	chats: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true
	}
};
export const autoReplyMessage = "メッセージを受け付けました";
const initChatMessage = (chats: Chat[]) => {
	const cs: Chat[] = [];
	for (const chat of chats) {
		cs.push(chat);
		if (!chat.fromAdmin) {
			cs.push({
				...chat,
				fromAdmin: true,
				message: autoReplyMessage
			});
		}
	}
	return cs;
};

export const reducer = (state: State, action: ChatAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		case "setInit":
			return {
				...state,
				...action.payload,
				chats: initChatMessage(action.payload.chats),
				loading: false,
				info: { message: "", isSuccess: true }
			};
		case "addChat":
			return {
				...state,
				chats: state.chats.concat(action.payload)
			};
		case "addAutoReply":
			return {
				...state,
				chats: state.chats.concat({
					...action.payload,
					fromAdmin: true,
					message: autoReplyMessage
				})
			};
		default:
			return state;
	}
};
