import * as React from "react";
import { AccountPage } from "../../components/pages/account";
import { get, post, put, makeError, remove } from "../../lib/request";
import { InfoMessage } from "lu-component";

import { Account } from "../../../../server/model/account";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError, AxiosResponse } from "axios";
import {
	GetAccountResponse,
	UpsertAccountResponse,
	DeleteAccountResponse
} from "../../../../server/types/request/account";
import { reducer, initState } from "../../reducers/account";

export const AccountContainter = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { accounts, info } = state;

	React.useEffect(() => {
		get<GetAccountResponse>("/accounts")
			.then(response => {
				dispatch({ type: "setAccounts", payload: response.data.accounts });
				dispatch({
					type: "changeMessageInfo",
					payload: { message: "", isSuccess: true }
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onUpsert = React.useCallback(
		(account: Account, cb?: () => void) => {
			let request: Promise<AxiosResponse<UpsertAccountResponse>>;
			const id = account._id;
			if (id) {
				request = put(`/account/${id}`, account);
			} else {
				request = post("/account", account);
			}
			request
				.then(response => {
					if (cb) cb();
					dispatch({
						type: id ? "changeAccount" : "addAccount",
						payload: response.data.account
					});
					dispatch({
						type: "changeMessageInfo",
						payload: {
							message: `アカウントの${id ? "更新" : "登録"}が完了しました。`,
							isSuccess: true
						}
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[accounts]
	);
	const onDelete = React.useCallback(
		(id: string) => {
			remove<DeleteAccountResponse>("/account", id)
				.then(response => {
					dispatch({
						type: "deleteAccount",
						payload: response.data.id
					});
					dispatch({
						type: "changeMessageInfo",
						payload: {
							message: "アカウントの削除が完了しました。",
							isSuccess: true
						}
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[accounts]
	);
	const onClose = React.useCallback(() => {
		dispatch({
			type: "changeMessageInfo",
			payload: { message: "", isSuccess: true }
		});
	}, []);
	return (
		<>
			<InfoMessage info={info} />
			<AccountPage
				onCloseEvent={onClose}
				accounts={accounts}
				onUpsert={onUpsert}
				errors={info.errors}
				onDelete={onDelete}
			/>
		</>
	);
};
