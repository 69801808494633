import * as React from "react";
import {
	FormControl,
	FormLabel,
	FormGroup,
	Row,
	Button,
	Alert,
	Navbar,
	Col,
	Card
} from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";
import { ErrorObject } from "../../../../../server/validation/validation-base";

// #c20131
export type LoginPageProps = {
	error: string;
	errors?: ErrorObject;
	onLogin: (email: string, password: string) => void;
};
export const LoginPage = React.memo((props: LoginPageProps) => {
	const { onLogin, error, errors } = props;
	const [mail, setMail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const changeID = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setMail(event.target.value),
		[]
	);
	const changePassword = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) =>
			setPassword(event.target.value),
		[]
	);
	const isDisabled = React.useMemo<boolean>(() => {
		return mail === "" || password === "";
	}, [mail, password]);
	const onSubmit = React.useCallback(() => onLogin(mail, password), [
		mail,
		password
	]);
	return (
		<>
			<Navbar bg={"primary"} variant={"dark"}>
				<Navbar.Brand>NI-HUT-DX</Navbar.Brand>
			</Navbar>
			<Card className={"m-5 pt-5 pb-5"}>
				<Card.Body>
					{error && (
						<div className="justify-content-md-center">
							<Alert variant={"danger"}>{error}</Alert>
						</div>
					)}
					<FormGroup as={Row} className="justify-content-md-center">
						<FormLabel column sm={2}>
							ID
						</FormLabel>
						<Col sm={6}>
							<FormControl
								placeholder="****@example.com"
								onChange={changeID}
								isInvalid={!!error || (!!mail && errors && "mail" in errors)}
							/>
							{errors && "mail" in errors && (
								<Feedback type={"invalid"}>{errors["mail"]}</Feedback>
							)}
						</Col>
					</FormGroup>
					<FormGroup as={Row} className="justify-content-md-center">
						<FormLabel column sm={2}>
							password
						</FormLabel>
						<Col sm={6}>
							<FormControl
								type="password"
								placeholder="Password"
								onChange={changePassword}
								isInvalid={
									!!error || (!!password && errors && "password" in errors)
								}
							/>
							{errors && "password" in errors && (
								<Feedback type={"invalid"}>{errors["password"]}</Feedback>
							)}
						</Col>
					</FormGroup>
					<Row className="justify-content-md-center">
						<Col sm={4}>
							<Button block disabled={isDisabled} onClick={onSubmit}>
								Login
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
});
