import { Labels } from ".";
import { Job, JobItem } from "../model/job";

export const jobLabels: Labels<Job> = {
	_id: "job",
	name: "job名",
	jobNo: "jobNo.",
	accountId: "担当者",
	infoName: "モニター向けアンケートタイトル",
	board: "掲示板",
	board2: "掲示板2",
	password: "マイページログインパスワード",
	status: "ステータス",
	isDelete: "削除フラグ",
	clientName: "クライアント名"
};

export const jobItemLabel: Labels<JobItem> = {
	...jobLabels,
	answerdCountDisplay: "回収数"
};
