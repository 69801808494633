import { Labels } from ".";
import { Survey, SurveyWithReminder } from "../model/survey";

export const surveyLabels: Labels<Survey> = {
	_id: "調査",
	jobEnqueteId: "アンケート",
	name: "カレンダー表示名",
	urlParam: "urlParameter",
	startDatetime: "開始日時",
	endDatetime: "終了日時",
	note: "備考",
	enableAnswerForClosedSurvey: "期限後に回答可能",
	count: "回収数"
};

export const surveyWithReminderLabels: Labels<SurveyWithReminder> = {
	...surveyLabels,
	reminders: "督促設定"
};
