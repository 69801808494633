import { Labels } from ".";
import { Plan } from "../model/plan";

export const planLabels: Labels<Plan> = {
	_id: "予定",
	jobId: "job",
	title: "カレンダー表示名",
	startDatetime: "開始日時",
	endDatetime: "終了日時",
	type: "プランタイプ"
};
