import * as React from "react";
import { Modal, Button } from "react-bootstrap";

export type InfoProps = {
	show: boolean;
	title: string;
	message: string;
	onClose: () => void;
};
export const Info = React.memo((props: InfoProps) => {
	const { show, title, message, onClose } = props;
	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header>
				<div style={{ textAlign: "left" }}>{title}</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ whiteSpace: "pre-wrap" }}>{message}</div>
			</Modal.Body>
			<Modal.Footer style={{ textAlign: "right" }}>
				<Button variant={"outline-info"} onClick={onClose}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
});
