import * as React from "react";
import { post } from "../../lib/request";
import { LogoutPage } from "../../components/pages/logout";

export const LogoutContainter = () => {
	const changeLocation = React.useCallback(
		() => location.replace("/login"),
		[]
	);
	const [isLogout, setIslogout] = React.useState<boolean>(false);
	React.useEffect(() => {
		post("/logout", {}).then(() => setIslogout(true));
	}, []);
	return isLogout && <LogoutPage changeLocation={changeLocation} />;
};
