import { JobEnqueteAction } from "../types";
import { MessageInfo } from "../types/info";
import { JobEnqueteItem } from "../../../server/model/job-enquete";

export type State = {
	jobEnquetes: JobEnqueteItem[];
	loading: boolean;
	info: MessageInfo;
};

export const initState: State = {
	jobEnquetes: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: JobEnqueteAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		case "setJobEnquetes":
			return {
				...state,
				jobEnquetes: action.payload,
				loading: false
			};
		case "deleteJobEnquete":
			return {
				...state,
				jobEnquetes: state.jobEnquetes.filter(je => je._id !== action.payload),
				info: {
					message: "削除が完了しました。",
					isSuccess: true
				}
			};
		case "copyJobEnquete":
			return {
				...state,
				jobEnquetes: state.jobEnquetes.reduce((a, b) => {
					a.push(b);
					if (b._id.toString() === action.payload.id) {
						a.push(action.payload.jobEnquete);
					}
					return a;
				}, []),
				info: {
					message: "削除が完了しました。",
					isSuccess: true
				}
			};
		case "clearJobEnquete":
			return {
				...state,
				jobEnquetes: state.jobEnquetes.map(je => {
					if (je._id === action.payload._id) {
						return action.payload;
					}
					return je;
				}),
				info: {
					message: "データクリアが完了しました。",
					isSuccess: true
				}
			};
		default:
			return state;
	}
};
