import { Labels } from ".";
import { Account } from "../model/account";

export const accountLabels: Labels<Account> = {
	_id: "アカウント",
	name: "名前",
	mail: "メールアドレス",
	password: "パスワード",
	isActive: "アクティブ",
	isAdmin: "管理者"
};
