import { Respondent } from "../model/respondent";
import { makeBaseRespondentUrl } from "./url";
import { respondentLabels } from "../label/respondent";
import { ObjectId } from "mongodb";

const labelKeyRespondents: { [key: string]: keyof Respondent } = Object.entries(
	respondentLabels
).reduce((a, [b1, b2]) => {
	return { ...a, [b2]: b1 };
}, {});

// 個人情報を基本的には含んではいけないので、privacy-modeを作る。
export const replacePlaceholder = (
	text: string,
	respondent: Respondent,
	permitPrivacy?: boolean
): string => {
	if (!text) return undefined;
	let replaceText = text;
	let matches = replaceText.match(/\[\[\[([^\\[]+)\]\]\]/);
	while (matches) {
		const [full, m] = matches;
		console.log(full, m);
		// URLの場合はマイページを表示
		if (m === "URL") {
			replaceText = replaceText.replace(
				full,
				`${makeBaseRespondentUrl()}/${respondent.urlParam}`
			);
			// モニターのラベルと一致する場合はその値
		} else if (
			permitPrivacy &&
			m in labelKeyRespondents &&
			respondent[labelKeyRespondents[m]]
		) {
			replaceText = replaceText.replace(
				full,
				respondent[labelKeyRespondents[m]].toString()
			);
			// 対象者コレクションのkey値の場合
		} else if (m in respondent) {
			replaceText = replaceText.replace(
				full,
				respondent[m as keyof Respondent].toString()
			);
			// その他の内容の場合
		} else if (m in respondent.otherInfo) {
			replaceText = replaceText.replace(full, respondent.otherInfo[m]);
		} else {
			// 上記以外は空欄とする。
			replaceText = replaceText.replace(full, "");
		}
		matches = replaceText.match(/\[\[\[([^\\[]+)\]\]\]/);
	}
	return replaceText;
};

export const makeRespondentKeyId = (
	realRespondentId: string,
	jobId: string | ObjectId
) => {
	return `${jobId.toString()}x${realRespondentId}`;
};

export const makeRealRespondentId = (
	respondentId: string,
	jobId: string | ObjectId
) => {
	return respondentId.replace(`${jobId.toString()}x`, "");
};

export const isSamsungBrowser = (ua: string) => {
	return /SamsungBrowser/i.test(ua);
};
