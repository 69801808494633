import * as React from "react";
import { reducer, initState } from "../../reducers/mypage";
import { MyPage } from "../../components/pages/mypage";
import { useHistory } from "react-router";
import { Caution } from "../../components/parts/caution";
import { get, makeError } from "../../lib/request";
import { GetMyPageResponse } from "../../../../server/types/request/mypage";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";

import { isSamsungBrowser } from "../../lib/common";

export const MyPageContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const history = useHistory();
	const { info, loading, ...mypageProps } = state;
	const onChatPage = React.useCallback(() => history.push(`/chat`), []);

	React.useEffect(() => {
		// ieはリクエストのgetもキャッシュされるので、その対応
		get<GetMyPageResponse>(`/mypages`, { a: Math.random() })
			.then(response => {
				dispatch({ type: "setInit", payload: response.data });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	return (
		<>
			{loading && !info.isSuccess && <Caution show message={info.message} />}
			<MyPage {...mypageProps} onChatPage={onChatPage} />
		</>
	);
};
