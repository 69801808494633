import * as React from "react";
import { JobPage } from "../../components/pages/job";
import { get, post, put, remove, makeError } from "../../lib/request";
import { InfoMessage } from "lu-component";
import { useHistory } from "react-router";

import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError, AxiosResponse } from "axios";
import { Job } from "../../../../server/model/job";
import {
	GetJobResponse,
	UpsertJobResponse,
	DeleteJobResponse,
	GetInitJobResponse
} from "../../../../server/types/request/job";
import { reducer, initState } from "../../reducers/job";

export const JobContainter = () => {
	const history = useHistory();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { jobs, info, accounts, accountsWithoutDeleted, search, loading } = state;
	const onClose = React.useCallback(() => {
		dispatch({
			type: "changeMessageInfo",
			payload: { message: "", isSuccess: true }
		});
	}, []);
	const onSearch = React.useCallback((job: Partial<Job>) => {
		get<GetJobResponse>("/jobs", job)
			.then(response => {
				dispatch({ type: "setJobs", payload: response.data.jobs });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	React.useEffect(() => {
		get<GetInitJobResponse>("/jobs/load")
			.then(response => {
				dispatch({ type: "loadJobs", payload: response.data });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onUpsert = React.useCallback(
		(job: Job, cb?: () => void) => {
			let request: Promise<AxiosResponse<UpsertJobResponse>>;
			const id = job._id;
			if (id) {
				request = put(`/job/${id}`, job);
			} else {
				request = post("/job", job);
			}
			request
				.then(response => {
					if (cb) cb();
					dispatch({
						type: id ? "changeJob" : "addJob",
						payload: response.data.job
					});
					dispatch({
						type: "changeMessageInfo",
						payload: {
							message: `${id ? "更新" : "作成"}が完了しました。`,
							isSuccess: true
						}
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[jobs]
	);
	const onDelete = React.useCallback(
		(id: string) => {
			remove<DeleteJobResponse>("/job", id)
				.then(response => {
					dispatch({ type: "deleteJob", payload: response.data.id });
					dispatch({
						type: "changeMessageInfo",
						payload: {
							message: "削除が完了しました。",
							isSuccess: true
						}
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[jobs]
	);
	const onCopy = React.useCallback((job: Job, cb?: () => void) => {
		post<UpsertJobResponse>("/copy-job", job)
			.then(response => {
				if (cb) cb();
				dispatch({
					type: "addJob",
					payload: response.data.job
				});
				dispatch({
					type: "changeMessageInfo",
					payload: {
						message: "作成が完了しました",
						isSuccess: true
					}
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onEnquetePage = React.useCallback((id: string) => {
		history.push(`/job-enquete/${id}`);
	}, []);
	const onDownload = React.useCallback((id: string) => {
		console.log(id);
		// TODO:download処理
	}, []);
	const onRespondentPage = React.useCallback((id: string) => {
		history.push(`/respondent/${id}`);
	}, []);
	const onChatPage = React.useCallback((id: string) => {
		history.push(`/chat-admin/${id}`);
	}, []);
	const onEnqueteManagePage = React.useCallback((id: string) => {
		history.push(`/survey/${id}`);
	}, []);
	return (
		<>
			<InfoMessage info={info} />
			{!loading && (
				<JobPage
					search={search}
					onCloseEvent={onClose}
					onCopy={onCopy}
					jobs={jobs}
					accounts={accounts}
					accountsWithoutDeleted={accountsWithoutDeleted}
					onSearch={onSearch}
					onUpsert={onUpsert}
					errors={info.errors}
					onDelete={onDelete}
					// 以下ページ遷移系
					onEnquetePage={onEnquetePage}
					onDownload={onDownload}
					onRespondentPage={onRespondentPage}
					onChatPage={onChatPage}
					onEnqueteManagePage={onEnqueteManagePage}
				/>
			)}
		</>
	);
};
