import * as React from "react";
import { useParams } from "react-router";
import { InfoMessage } from "lu-component";
import { SurveyPage } from "../../components/pages/survey";
import { ErrorResponse } from "../../../../server/types/request";
import { get, post, put, remove, makeError } from "../../lib/request";
import { AxiosError, AxiosResponse } from "axios";
import { Survey } from "../../../../server/model/survey";
import { Plan } from "../../../../server/model/plan";
import { Reminder } from "../../../../server/model/reminder";
import {
	GetSurveyResponse,
	UpsertSurveyResponse
} from "../../../../server/types/request/survey";
import { UpsertPlanRrsponse } from "../../../../server/types/request/plan";
import { reducer, initState } from "../../reducers/survey";

export const SurveyContainer = () => {
	const { jobId } = useParams();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { job, surveys, plans, jobEnquetes, info } = state;
	React.useEffect(() => {
		get<GetSurveyResponse>(`/surveys/${jobId}`)
			.then(response => {
				dispatch({
					type: "setSurveysAndPlansAndOptions",
					payload: response.data
				});
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onUpsertSurvey = React.useCallback(
		(survey: Survey, reminders: Reminder[], cb?: () => void) => {
			let request: Promise<AxiosResponse<UpsertSurveyResponse>>;
			const id = survey._id;
			if (id) {
				request = put(`/survey/${id}`, { survey, reminders });
			} else {
				request = post("/survey", { survey, reminders });
			}
			request
				.then(response => {
					if (cb) cb();
					dispatch({
						type: id ? "changeSurvey" : "addSurvey",
						payload: response.data.survey
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[surveys]
	);
	const onUpsertPlan = React.useCallback(
		(plan: Plan, reminders: Reminder[], cb?: () => void) => {
			plan.jobId = jobId;
			let request: Promise<AxiosResponse<UpsertPlanRrsponse>>;
			const id = plan._id;
			if (id) {
				request = put(`/plan/${id}`, { plan, reminders });
			} else {
				request = post("/plan", { plan, reminders });
			}
			request
				.then(response => {
					if (cb) cb();
					dispatch({
						type: id ? "changePlan" : "addPlan",
						payload: response.data.plan
					});
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[plans]
	);
	const onDeleteSurvey = React.useCallback(
		(id: string) => {
			remove("/survey", id)
				.then(() => {
					dispatch({ type: "deleteSurvey", payload: id });
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[surveys]
	);
	const onDeletePlan = React.useCallback(
		(id: string) => {
			remove("/plan", id)
				.then(() => {
					dispatch({ type: "deletePlan", payload: id });
				})
				.catch((error: AxiosError<ErrorResponse>) => {
					dispatch({
						type: "changeMessageInfo",
						payload: makeError(error)
					});
				});
		},
		[plans]
	);
	const onClearInfo = React.useCallback(() => {
		dispatch({
			type: "changeMessageInfo",
			payload: { message: "", isSuccess: false }
		});
	}, []);
	return (
		<>
			<InfoMessage info={info} />
			<SurveyPage
				job={job}
				jobEnquetes={jobEnquetes}
				surveys={surveys}
				plans={plans}
				onUpsertSurvey={onUpsertSurvey}
				onDeleteSurvey={onDeleteSurvey}
				onUpsertPlan={onUpsertPlan}
				onDeletePlan={onDeletePlan}
				onClearInfo={onClearInfo}
				errors={info.errors}
			/>
		</>
	);
};
