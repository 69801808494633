import * as React from "react";
import { Reminder } from "../../../../../server/model/reminder";
import { Survey } from "../../../../../server/model/survey";
import { Job } from "../../../../../server/model/job";
import { Plan } from "../../../../../server/model/plan";
import styled from "styled-components";
import { ErrorObject, Title } from "lu-component";
import { Row, Col, Button } from "react-bootstrap";
import { ReminderForm } from "../reminder-form";

const NoneReminder = styled.div`
	text-align: cente;
	padding: 10px;
	background-color: #ddd;
	margin: 10px;
	border-radius: 10px;
`;
const SubTitle = styled.div`
	padding: 5px;
	margin: 10px;
	border-bottom: 1px solid #ddd;
`;

export type RemindersFormProps = {
	label: string;
	target: Plan | Survey;
	job: Job;
	errors?: ErrorObject;
	reminders: Reminder[];
	onChange: (reminders: Reminder[]) => void;
};

export const RemindersForm = React.memo((props: RemindersFormProps) => {
	const { reminders, target, job, errors, label, onChange } = props;
	const onDeleteReminder = React.useCallback(
		(index: number) => {
			const tmp = reminders.concat();
			tmp.splice(index, 1);
			onChange(tmp);
		},
		[reminders]
	);
	const onAddReminder = React.useCallback(() => {
		onChange(
			reminders.concat({
				title: job.infoName || "",
				message: "",
				sendDatetime: "",
				sendType: "both",
				...("type" in target
					? { planId: target._id || "" }
					: { surveyId: target._id || "" })
			})
		);
	}, [reminders, job, target]);
	const onChangeReminder = React.useCallback(
		(index: number, name: keyof Reminder, value: string) => {
			const tmp = reminders.concat();
			tmp[index] = { ...tmp[index], [name]: value };
			onChange(tmp);
		},
		[reminders]
	);
	const reminderErrors = React.useMemo(() => {
		if (errors && "reminders" in errors) {
			return errors["reminders"] as ErrorObject;
		}
		return undefined;
	}, [errors]);
	return (
		<Row>
			<Col md={12}>
				<Title>{label}設定</Title>
			</Col>
			<Col md={{ offset: 9, span: 3 }}>
				<Button variant={"outline-success"} onClick={onAddReminder}>
					{label}追加
				</Button>
			</Col>
			<Col md={12}>
				{reminders.length === 0 && <NoneReminder>{label}設定なし</NoneReminder>}
				{reminders.map((reminder, index) => {
					const tmp = index.toString();
					const err =
						reminderErrors && tmp in reminderErrors
							? (reminderErrors[tmp] as ErrorObject)
							: undefined;
					return (
						<React.Fragment key={`reminder-${index}`}>
							<Row style={{ marginTop: "10px" }}>
								<Col md={12}>
									<SubTitle>
										{index + 1}個目の{label}
									</SubTitle>
								</Col>
								<Col md={{ offset: 11, span: 1 }}>
									<Button
										variant={"danger"}
										onClick={() => onDeleteReminder(index)}
									>
										×
									</Button>
								</Col>
								<Col md={12}>
									<ReminderForm
										onChange={(name: keyof Reminder, value: string) =>
											onChangeReminder(index, name, value)
										}
										errors={err}
										reminder={reminder}
									/>
								</Col>
							</Row>
							<hr />
						</React.Fragment>
					);
				})}
			</Col>
		</Row>
	);
});
