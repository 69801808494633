import * as React from "react";
import styled from "styled-components";
import { Navbar, Nav, Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Job } from "../../../../../server/model/job";

const NavWrap = styled.div`
	height: 56px;
	& .navbar {
		background-color: #2b6ad6;
	}
	& .navbar-brand {
		padding: 3px 0px;
		font-size: 1.2rem;
	}
	& .custom-brand {
		overflow: hidden;
		display: block;
		flex: 1;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	& .icon-area {
		flex-grow: 0;
	}
`;
const Badge = styled.div`
	position: absolute;
	right: 8px;
	border-radius: 14px;
	width: 14px;
	height: 14px;
	background-color: red;
	text-align: center;
	font-size: 10px;
	color: white;
`;
const IconItem = styled.div`
	cursor: pointer;
	& :hover {
		color: white;
	}
`;

export type MyPageNavigationBarProps = {
	title?: string;
	onChatPage?: () => void;
	unopenedMessage?: number;
	onMyPage?: () => void;
};
export const MyPageNavigationBar = React.memo(
	(props: MyPageNavigationBarProps) => {
		const { title, onChatPage, unopenedMessage, onMyPage } = props;
		const isSmartphone = React.useMemo(() => {
			return window.innerWidth < 500;
		}, []);
		return (
			<NavWrap className={"custom-nav"}>
				<Navbar variant={"dark"}>
					<Navbar.Brand className={"custom-brand"}>{title || ""}</Navbar.Brand>
					<Navbar.Collapse className={"justify-content-end icon-area"}>
						<Nav>
							{onChatPage && (
								<Navbar.Text>
									<IconItem onClick={onChatPage}>
										{!isSmartphone && (
											<span style={{ fontSize: "0.8em" }}>チャットへ　</span>
										)}
										{unopenedMessage > 0 && <Badge>{unopenedMessage}</Badge>}
										<FontAwesomeIcon
											icon={faComments}
											color={"#eee"}
											size={"lg"}
										/>
									</IconItem>
								</Navbar.Text>
							)}
							{onMyPage && (
								<Navbar.Text>
									<IconItem onClick={onMyPage}>
										{!isSmartphone && (
											<span style={{ fontSize: "0.8em" }}>マイページへ　</span>
										)}
										<FontAwesomeIcon
											icon={faCalendarAlt}
											color={"#eee"}
											size={"lg"}
										/>
									</IconItem>
								</Navbar.Text>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</NavWrap>
		);
	}
);
