import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { ErrorResponse } from "../../../server/types/request";
import { MessageInfo } from "../types/info";

const meta = document.querySelector('meta[name="csrf-token"]');
if (meta) {
	axios.defaults.headers.common = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRF-TOKEN": meta.getAttribute("content")
	};
}
export const makeError = (
	error: AxiosError<ErrorResponse>,
	expandObjects?: boolean
): MessageInfo => {
	if (error && error.response && error.response.data) {
		const { message, errors } = error.response.data;
		if (expandObjects) {
			return {
				message:
					message + (errors ? "\n" + Object.values(errors).join("\n") : ""),
				isSuccess: false
			};
		}
		return { message, errors, isSuccess: false };
	}
	return {
		message: "通信エラーが発生しました。ページをリロードしてください",
		isSuccess: false
	};
};
export const post = async <T>(
	url: string,
	data?: { [key: string]: any },
	options?: AxiosRequestConfig
) => {
	return await axios.post<T>(url, data, options);
};
export const postFormData = async <T>(
	url: string,
	data: FormData,
	options?: AxiosRequestConfig
) => {
	data.append("_csrf", meta.getAttribute("content"));
	return await axios.post<T>(url, data, {
		...(options ? options : {}),
		headers: {
			"content-type": "multipart/form-data"
		}
	});
};
export const get = async <T>(
	url: string,
	data?: { [key: string]: any },
	options?: AxiosRequestConfig
) => {
	if (data) {
		const queries = Object.entries(data)
			.filter(([, value]) => value !== "")
			.map(([key, value]) => `${key}=${value}`)
			.join("&");
		url += "?" + queries;
	}
	return await axios.get<T>(url, options);
};
export const put = async <T>(
	url: string,
	data?: any,
	options?: AxiosRequestConfig
) => {
	return await axios.put<T>(url, data, options);
};

export const remove = async <T>(
	url: string,
	id: string,
	options?: AxiosRequestConfig
) => {
	return await axios.delete<T>(`${url}/${id}`, options);
};

export const fileDownload = async (url: string, filename: string) => {
	const response = await axios.get(url, { responseType: "blob" });
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(
		new Blob([response.data], {
			type: response.data.type
		})
	);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	link.remove();
};
