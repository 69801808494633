import { ObjectId } from "mongodb";

export type SendType = "sms" | "mail" | "both";

export type Reminder = {
	_id?: ObjectId | string;

	// planIdで切り分けを行う。
	surveyId?: ObjectId | string;
	planId?: ObjectId | string;

	title: string; // メールの場合タイトルがないと送れないので。
	message: string;
	sendDatetime: string;
	sendEndDatetime?: string; // 督促配信の終了時間。
	sendType: SendType;
};
export const SendTypeOption: { label: string; value: SendType }[] = [
	{ label: "E-mailのみ", value: "mail" },
	{ label: "SMSのみ", value: "sms" },
	{ label: "Email & SMS", value: "both" }
];
