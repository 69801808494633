import * as React from "react";
import { Job } from "../../../../../server/model/job";
import { Column, CustomForm, ErrorObject } from "lu-component";
import { Button, Modal, Alert } from "react-bootstrap";

export type CopyJobFormProps = {
	job: Job;
	columns: Column[][];
	errors: ErrorObject;
	onCopy: (job: Job, cb?: () => void) => void;
};

export const CopyJobForm = React.memo((props: CopyJobFormProps) => {
	const { columns, errors, onCopy } = props;
	const [show, setShow] = React.useState<boolean>(false);
	const [job, setJob] = React.useState<Job>(props.job);
	const onClose = React.useCallback(() => {
		setShow(false);
		setJob(job);
	}, [props.job]);
	const onSubmit = React.useCallback((data: Job) => onCopy(data, onClose), [
		onClose
	]);
	return (
		<>
			<Button
				variant={"outline-secondary"}
				onClick={() => setShow(true)}
				style={{ width: "80px" }}
			>
				コピー
			</Button>
			<Modal size="lg" show={show} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title>ジョブのコピー</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Alert variant={"warning"}>
						jobNo.、job名、アンケートタイトルのいずれかを変更してください
					</Alert>
					<CustomForm
						buttonText={"コピー"}
						columns={columns}
						onSubmit={onSubmit}
						errors={errors}
						data={job}
						variant={"outline-secondary"}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
});
