import { PrecedentType } from "../components/parts/precedent";

export const labels: PrecedentType[][] = [
	[
		{ color: "#7986cb", label: "予定" },
		{ color: "#ffa8a8", label: "返却期限" }
	],
	[
		{ color: "#c0ca33", label: "回答可能アンケート" },
		{ color: "#ddd", label: "回答済み・回答待ちアンケート" }
	]
];
