import { AccountAction } from "../types";
import { AccountWithoutPassowrd } from "../../../server/model/account";
import { MessageInfo } from "../types/info";

export type State = {
	accounts: AccountWithoutPassowrd[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	accounts: [],
	loading: false,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: AccountAction): State => {
	switch (action.type) {
		case "setAccounts":
			return { ...state, accounts: action.payload };
		case "addAccount":
			return {
				...state,
				accounts: state.accounts.concat(action.payload),
				info: { message: "アカウントの作成が完了しました。", isSuccess: true }
			};
		case "deleteAccount":
			return {
				...state,
				accounts: state.accounts.filter(
					account => account._id !== action.payload
				),
				info: { message: "アカウントの削除が完了しました。", isSuccess: true }
			};
		case "changeAccount":
			return {
				...state,
				accounts: state.accounts.map(account =>
					account._id === action.payload._id ? action.payload : account
				),
				info: { message: "アカウントの更新が完了しました。", isSuccess: true }
			};
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		default:
			return state;
	}
};
