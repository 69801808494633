import * as React from "react";
import { useHistory } from "react-router";
import { Info } from "../../components/parts/info";

const message = "アンケートの回答が完了しました。";
export const AnsweredContainer = () => {
	const history = useHistory();
	const [show, setShow] = React.useState<boolean>(true);
	const EnquetePage = React.useCallback(() => {
		setShow(false);
		history.push("/mypage");
	}, []);
	return (
		<Info
			show={show}
			onClose={EnquetePage}
			title={"回答完了"}
			message={message}
		/>
	);
};
