import * as React from "react";
import {
	Row,
	Col,
	Button,
	FormGroup,
	FormCheck,
	FormLabel
} from "react-bootstrap";
import { SurveyWithReminder, Survey } from "../../../../../server/model/survey";
import { Option } from "lu-component";
import { SurveyForm } from "../../parts/survey-form";
import {
	PostMessageClickCalendarPlan,
	CalendarPlan
} from "../../../fullcalendar/types";
import { Reminder } from "../../../../../server/model/reminder";
import { PlanWithReminder, Plan } from "../../../../../server/model/plan";
import { PlanForm } from "../../parts/plan-form";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import styled from "styled-components";
import { Precedent } from "../../parts/precedent";
import { labels } from "../../../lib/label";
import { Job } from "../../../../../server/model/job";
import { BoardViewArea } from "../mypage";

const SwitchButtonArea = styled.div`
	margin-bottom: 10px;
	& .btn-toolbar {
		justify-content: flex-end;
	}
`;

const ContainerArea = styled.div`
	max-width: 1000px;
	margin: 0px auto;
`;

export type SurveyPageProps = {
	job: Job;
	surveys: SurveyWithReminder[];
	plans: PlanWithReminder[];
	jobEnquetes: Option[];
	onUpsertSurvey: (
		survey: Survey,
		reminders: Reminder[],
		cb?: () => void
	) => void;
	onDeleteSurvey: (id: string) => void;
	onDeletePlan: (id: string) => void;
	onUpsertPlan: (plan: Plan, reminders: Reminder[], cb?: () => void) => void;
	onClearInfo: () => void;
	errors?: ErrorObject;
};
export const SurveyPage = React.memo((props: SurveyPageProps) => {
	const {
		onUpsertSurvey,
		jobEnquetes,
		job,
		surveys,
		plans,
		onUpsertPlan,
		onDeleteSurvey,
		onDeletePlan,
		errors,
		onClearInfo
	} = props;
	const ref = React.createRef<HTMLIFrameElement>();
	const [targetId, setTargetId] = React.useState<string>(undefined);
	const [targetSurvey, setTargetSurvey] = React.useState<SurveyWithReminder>();
	const [surveyShow, setSurveyShow] = React.useState<boolean>(false);

	const [planShow, setPlanShow] = React.useState<boolean>(false);
	const [dueDateShow, setDueDateShow] = React.useState<boolean>(false);
	const [targetPlan, setTargetPlan] = React.useState<PlanWithReminder>();
	const [showReminder, setShowReminder] = React.useState<boolean>(true);

	React.useEffect(() => {
		if (targetId) {
			const survey = surveys.find(survey => {
				if (survey._id === targetId) return true;
				const target = survey.reminders.find(r => r._id === targetId);
				if (target) return true;
				return false;
			});
			if (survey) {
				setTargetSurvey(survey);
				setSurveyShow(true);
			} else {
				const plan = plans.find(plan => {
					if (plan._id === targetId) return true;
					const target = plan.reminders.find(r => r._id === targetId);
					if (target) return true;
					return false;
				});
				if (plan) {
					setTargetPlan(plan);
					if (plan.type === "dueDate") {
						setDueDateShow(true);
					} else {
						setPlanShow(true);
					}
				}
			}
		} else {
			setTargetSurvey(undefined);
		}
	}, [targetId, surveys, plans]);
	React.useEffect(() => {
		const origin = process.env.APP_URL;
		if (ref.current) {
			ref.current.onload = () => {
				window.addEventListener("message", ev => {
					if (ev.origin !== origin) return false;
					if (!ev.data || ev.data.type !== "onClickCadendarPlan") return;
					const data: PostMessageClickCalendarPlan = ev.data;
					setTargetId(data.payload);
				});
			};
		}
	}, [ref]);
	React.useEffect(() => {
		const calendarPlan: CalendarPlan[] = [];
		for (const s of surveys) {
			calendarPlan.push({
				_id: s._id.toString(),
				name: s.name,
				startDatetime: s.startDatetime,
				endDatetime: s.endDatetime,
				bgColor: s.enableAnswer ? "#c0ca33" : "#ddd",
				enableAnswer: s.enableAnswer || false,
				color: s.enableAnswer ? "white" : "#555"
			});
			if (showReminder) {
				for (const r of s.reminders) {
					calendarPlan.push({
						_id: r._id.toString(),
						name: r.title,
						startDatetime: r.sendDatetime,
						endDatetime: r.sendDatetime,
						bgColor: r.sendEndDatetime ? "#ddd" : "#fc903d",
						enableAnswer: false,
						color: r.sendEndDatetime ? "#555" : "white"
					});
				}
			}
		}
		for (const p of plans) {
			calendarPlan.push({
				_id: p._id.toString(),
				name: p.title,
				startDatetime: p.startDatetime,
				endDatetime: p.endDatetime,
				bgColor: p.type === "dueDate" ? "#ffa8a8" : "#7986cb",
				enableAnswer: false
			});
			if (showReminder) {
				for (const r of p.reminders) {
					calendarPlan.push({
						_id: r._id.toString(),
						name: r.title,
						startDatetime: r.sendDatetime,
						endDatetime: r.sendDatetime,
						bgColor: r.sendEndDatetime ? "#ddd" : "#fc903d",
						enableAnswer: false,
						color: r.sendEndDatetime ? "#555" : "white"
					});
				}
			}
		}
		setTimeout(() => {
			if (ref.current && ref.current.contentWindow) {
				ref.current.contentWindow.postMessage(
					{
						type: "loadCalendarPlan",
						payload: calendarPlan
					},
					"*"
				);
			}
		}, 500);
	}, [ref, surveys, plans, showReminder]);
	const onClickNewDueDate = React.useCallback(() => {
		setTargetPlan(undefined);
		setTargetId(undefined);
		setDueDateShow(true);
	}, []);
	const onClickNewPlan = React.useCallback(() => {
		setTargetPlan(undefined);
		setTargetId(undefined);
		setPlanShow(true);
	}, []);
	const onClosePlan = React.useCallback(() => {
		setTargetPlan(undefined);
		setTargetId(undefined);
		setPlanShow(false);
		onClearInfo();
	}, [onClearInfo]);
	const onCloseDueDate = React.useCallback(() => {
		setTargetPlan(undefined);
		setTargetId(undefined);
		setDueDateShow(false);
		onClearInfo();
	}, [onClearInfo]);
	const onClickNewSurvey = React.useCallback(() => {
		setTargetSurvey(undefined);
		setTargetId(undefined);
		setSurveyShow(true);
	}, []);
	const onCloseSurvey = React.useCallback(() => {
		setTargetSurvey(undefined);
		setTargetId(undefined);
		setSurveyShow(false);
		onClearInfo();
	}, [onClearInfo]);

	const onChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setShowReminder(e.target.checked);
		},
		[]
	);
	return (
		<>
			<Row>
				<Col md={12}>
					<div className={"h3"}>■ スケジュール登録</div>
				</Col>
			</Row>
			<ContainerArea>
				<Row>
					<Col md={{ offset: 8, span: 4 }}>
						<SwitchButtonArea>
							<FormGroup>
								<FormCheck onChange={onChange} checked={showReminder} inline />
								<FormLabel>督促表示</FormLabel>
							</FormGroup>
						</SwitchButtonArea>
					</Col>
					<Col
						md={{ offset: 5, span: 3 }}
						style={{ marginBottom: "10px", textAlign: "right" }}
					>
						<Button onClick={onClickNewSurvey} variant={"outline-primary"}>
							アンケート・督促設定
						</Button>
					</Col>
					<SurveyForm
						errors={errors}
						show={surveyShow}
						job={job}
						survey={
							targetSurvey
								? {
										_id: targetSurvey._id,
										jobEnqueteId: targetSurvey.jobEnqueteId,
										name: targetSurvey.name,
										urlParam: targetSurvey.urlParam,
										note: targetSurvey.note,
										startDatetime: targetSurvey.startDatetime,
										endDatetime: targetSurvey.endDatetime,
										enableAnswerForClosedSurvey:
											targetSurvey.enableAnswerForClosedSurvey
								  }
								: undefined
						}
						jobEnquetes={jobEnquetes}
						onSave={(survey, reminders) =>
							onUpsertSurvey(survey, reminders, onCloseSurvey)
						}
						reminders={targetSurvey ? targetSurvey.reminders : undefined}
						onClose={onCloseSurvey}
						onDelete={onDeleteSurvey}
					/>
					<Col md={2} style={{ marginBottom: "10px", textAlign: "right" }}>
						<Button onClick={onClickNewDueDate} variant={"outline-success"}>
							返却期限追加
						</Button>
					</Col>
					<PlanForm
						errors={errors}
						show={dueDateShow}
						plan={
							targetPlan
								? {
										_id: targetPlan._id,
										jobId: targetPlan.jobId,
										type: targetPlan.type,
										title: targetPlan.title,
										startDatetime: targetPlan.startDatetime,
										endDatetime: targetPlan.endDatetime
								  }
								: undefined
						}
						reminders={targetPlan ? targetPlan.reminders : undefined}
						job={job}
						onSave={(plan, reminders) =>
							onUpsertPlan(plan, reminders, onCloseDueDate)
						}
						onClose={onCloseDueDate}
						onDelete={onDeletePlan}
						isDueDate
					/>
					<Col md={2} style={{ marginBottom: "10px", textAlign: "right" }}>
						<Button onClick={onClickNewPlan} variant={"outline-success"}>
							予定追加
						</Button>
					</Col>
					<PlanForm
						errors={errors}
						show={planShow}
						plan={
							targetPlan
								? {
										_id: targetPlan._id,
										jobId: targetPlan.jobId,
										type: targetPlan.type,
										title: targetPlan.title,
										startDatetime: targetPlan.startDatetime,
										endDatetime: targetPlan.endDatetime
								  }
								: undefined
						}
						reminders={targetPlan ? targetPlan.reminders : undefined}
						job={job}
						onSave={(plan, reminders) =>
							onUpsertPlan(plan, reminders, onClosePlan)
						}
						onClose={onClosePlan}
						onDelete={onDeletePlan}
					/>
				</Row>
				<Row>
					<Col md={12}>
						{job && job.board && (
							<BoardViewArea dangerouslySetInnerHTML={{ __html: job.board }} />
						)}
						{job && job.board2 && (
							<BoardViewArea dangerouslySetInnerHTML={{ __html: job.board2 }} />
						)}
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Precedent
							labelGroups={[
								...labels,
								[{ color: "#fc903d", label: "督促配信" }]
							]}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={12}>
						<iframe
							src="/child-iframe.html"
							style={{ width: "100%", height: "600px" }}
							ref={ref}
						/>
					</Col>
				</Row>
			</ContainerArea>
		</>
	);
});
