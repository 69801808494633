import * as React from "react";
import { Plan } from "../../../../../server/model/plan";
import { Column, FormInputGroups, ConfirmButton } from "lu-component";
import { Modal, Button } from "react-bootstrap";
import { planLabels } from "../../../../../server/label/plan";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import { Reminder } from "../../../../../server/model/reminder";
import { Job } from "../../../../../server/model/job";
import { RemindersForm } from "../reminders-form";

export type PlanFormProps = {
	plan?: Plan;
	job: Job;
	reminders?: Reminder[];
	onSave: (plan: Plan, reminders: Reminder[]) => void;
	onDelete: (id: string) => void;
	show: boolean;
	errors?: ErrorObject;
	onClose: () => void;
	isDueDate?: boolean;
};
const columns: Column<Plan>[][] = [
	[{ type: "text", name: "title", label: planLabels.title, col: 7 }],
	[
		{
			type: "datetime",
			name: "startDatetime",
			label: planLabels.startDatetime
		},
		{ type: "datetime", name: "endDatetime", label: planLabels.endDatetime }
	]
];
export const PlanForm = React.memo((props: PlanFormProps) => {
	const { onSave, onDelete, show, onClose, errors, job, isDueDate } = props;
	const [plan, setPlan] = React.useState<Plan>({
		jobId: undefined,
		title: "",
		startDatetime: "",
		endDatetime: "",
		type: isDueDate ? "dueDate" : "plan"
	});
	const [reminders, setReminders] = React.useState<Reminder[]>([]);
	React.useEffect(() => {
		setReminders(props.reminders || []);
	}, [props.reminders, show]);

	React.useEffect(() => {
		setPlan(
			props.plan || {
				jobId: undefined,
				title: "",
				startDatetime: "",
				endDatetime: "",
				type: isDueDate ? "dueDate" : "plan"
			}
		);
	}, [show, isDueDate]);

	const onChange = React.useCallback(
		(name: string, value: string) => {
			const changePlan = { ...plan, [name]: value };
			if (name === "startDatetime" && !changePlan.endDatetime) {
				changePlan.endDatetime = value.toString();
			}
			setPlan(changePlan);
		},
		[plan]
	);
	const onSaveClick = React.useCallback(() => onSave(plan, reminders), [
		onSave,
		plan,
		reminders
	]);
	const onDeleteClick = React.useCallback(() => {
		onClose();
		if (plan._id) onDelete(plan._id.toString());
	}, [plan, onClose]);
	const onCloseClick = React.useCallback(() => {
		onClose();
	}, [onClose]);
	return (
		<>
			<Modal size="lg" show={show} onHide={onCloseClick}>
				<Modal.Header closeButton>
					{isDueDate ? "返却期日" : "予定"}
					{plan._id ? "更新" : "作成"}
				</Modal.Header>
				<Modal.Body>
					<FormInputGroups
						errors={
							errors && "plan" in errors
								? (errors["plan"] as ErrorObject)
								: undefined
						}
						data={plan}
						onChange={onChange}
						columns={columns}
					/>
					<RemindersForm
						label={"予定通知"}
						target={plan}
						job={job}
						errors={errors}
						reminders={reminders}
						onChange={setReminders}
					/>
				</Modal.Body>
				<Modal.Footer style={{ textAlign: "right" }}>
					<Button variant={"outline-primary"} onClick={onSaveClick}>
						保存
					</Button>
					{plan._id && (
						<ConfirmButton
							title={"予定の削除"}
							buttonText={"削除"}
							body={"削除をすると元には戻せません。よろしいですか？"}
							onOk={onDeleteClick}
						/>
					)}
					<Button variant={"outline-secondary"} onClick={onCloseClick}>
						キャンセル
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
});
