import { ObjectId } from "mongodb";

export enum JobStatus {
	ongoing = "進行中",
	complete = "完了",
	stopping = "休止中"
}
export const jobStatusOptions = Object.entries(JobStatus)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export type Job = {
	_id?: ObjectId | string;
	jobNo: string;
	accountId: ObjectId | string; //担当者ID
	name: string; //ジョブ名称
	infoName: string; // モニター向け調査タイトル
	board: string; //掲示板
	board2?: string; //掲示板2

	status: keyof typeof JobStatus;
	isDelete: boolean; // 削除済みかどうかのフラグ。

	password: string; // ログインパスワード。配信のため、平文で登録とする。
	createdAt?: Date;
	updatedAt?: Date;

	clientName?: string;
};

export type JobItem = Job & {
	answerdCountDisplay: string;
};
