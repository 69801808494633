import * as React from "react";
import { Job } from "../../../../../server/model/job";
import { Chat } from "../../../../../server/model/chat";
import { MyPageNavigationBar } from "../../parts/mypage-navigation-bar";
import { ChatRoom, Message } from "lu-component";
import styled from "styled-components";

const Container = styled.div`
	height: 100%;
	overflow: hidden;
	div.message-area,
	div.send-area {
		max-width: 1000px;
		margin: 0px auto;
	}
	div.info-message {
		text-align: center;
		background-color: white;
		padding: 5px;
		font-size: 0.8em;
	}
	@media (max-width: 380px) {
		font-size: 0.75em;
	}
	div.title-area {
		height: 100px;
		background-color: #2b6ad6;
	}
	.navbar {
		max-width: 1000px;
		margin: 0px auto;
	}
	table {
		table-layout: fixed;
	}
`;
export type ChatPageProps = {
	job: Job;
	chats: Chat[];
	onSend: (message: string) => void;
	onMyPage: () => void;
};
export const ChatPage = React.memo((props: ChatPageProps) => {
	const { job, chats, onMyPage, onSend } = props;
	const messages = React.useMemo<Message[]>(() => {
		return chats.map(chat => {
			return {
				type: chat.fromAdmin ? "receive" : "send",
				text: chat.message,
				sendDatetime: chat.sendDatetime,
				openDatetime: chat.openDatetime
			};
		});
	}, [chats]);
	return (
		<Container>
			<ChatRoom
				title={
					<>
						<MyPageNavigationBar
							title={job ? job.infoName : ""}
							onMyPage={onMyPage}
						/>
						<div className={"info-message"}>
							担当者へのメッセージを、画面下の入力欄から送信してください
							<br />
							カレンダーのアイコンをクリックするとマイページに戻れます
						</div>
					</>
				}
				messages={messages}
				onSend={onSend}
				bgColor={"#F3F2F1"}
				sendStyle={{ bgColor: "#e5eaf1" }}
			/>
		</Container>
	);
});
