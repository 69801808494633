import * as React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

export type PrecedentType = {
	label: string;
	color: string;
};
export type PrecedentProps = {
	labelGroups: PrecedentType[][];
};
export const PrecedentsArea = styled.div`
	margin: 3px;
	border: 1px solid #ddd;
	padding: 7px;
	border-radius: 2px;
`;
const PrecedentBar = styled.div`
	display: inline-block;
	background-color: ${(props: { color: string }) => props.color};
	min-width: 50px;
	margin-right: 10px;
`;
const PrecedentLabel = styled.div`
	display: inline-block;
`;
const PrecedentItem = styled.div`
	margin: 3px 0px;
`;
export const Precedent = React.memo((props: PrecedentProps) => {
	const { labelGroups } = props;
	return (
		<PrecedentsArea>
			<Row>
				{labelGroups.map((labels, groupIndex) => {
					return (
						<Col md={"auto"} key={`label-group-${groupIndex}`}>
							{labels.map(({ label, color }, index) => {
								return (
									<PrecedentItem key={`label${groupIndex}-${index}`}>
										<PrecedentBar color={color}>　</PrecedentBar>
										<PrecedentLabel>{label}</PrecedentLabel>
									</PrecedentItem>
								);
							})}
						</Col>
					);
				})}
			</Row>
		</PrecedentsArea>
	);
});
