import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Respondent } from "../../../../../server/model/respondent";
import { FilterColumn, FilterTable, FileInput } from "lu-component";
import { respondentLabels } from "../../../../../server/label/respondent";

export type RespondentPageProps = {
	respondents: Respondent[];
	onUpload: (file: File) => void;
	onDownloadCsv: () => void;
	onDownloadQRCode: () => void;
};

export const RespondentPage = React.memo((props: RespondentPageProps) => {
	const { respondents, onUpload, onDownloadCsv, onDownloadQRCode } = props;
	const columns = React.useMemo(() => {
		const columns: FilterColumn[] = [
			{
				header: respondentLabels._id,
				dataKey: "_id"
			},
			{
				header: respondentLabels.name,
				dataKey: "name"
			},
			{
				header: respondentLabels.mail,
				dataKey: "mail"
			},
			{
				header: respondentLabels.mobileTel,
				dataKey: "mobileTel"
			},
			{
				header: respondentLabels.urlParam,
				dataKey: "urlParam"
			}
		];
		if (respondents.length) {
			for (const key of Object.keys(respondents[0].otherInfo)) {
				columns.push({ header: key, dataKey: key });
			}
		}
		return columns;
	}, [respondents]);
	const targetRespondents = React.useMemo(() => {
		return respondents.map(resp => {
			const { _id, otherInfo, ...other } = resp;
			return {
				...other,
				_id: _id.toString(),
				...otherInfo
			};
		});
	}, [respondents]);
	return (
		<Row>
			<Col md={12}>
				<div className={"h3"}>■ 対象者設定</div>
			</Col>
			<Col md={12}>
				<div className={"m-3"}>
					<FileInput onUpload={onUpload} label={"CSVアップロード"} />
					<Row>
						<Col md={3}>
							<Button variant={"secondary"} onClick={onDownloadCsv}>
								CSVダウンロード
							</Button>
						</Col>
						<Col md={3}>
							<Button variant={"secondary"} onClick={onDownloadQRCode}>
								QRコードダウンロード
							</Button>
						</Col>
					</Row>
					<FilterTable
						columns={columns}
						data={targetRespondents as { [key: string]: string }[]}
					/>
				</div>
			</Col>
		</Row>
	);
});
