import * as React from "react";
import { LoginPage } from "../../components/pages/login";
import { post } from "../../lib/request";
import { useHistory } from "react-router";
import {
	LoginRequest,
	LoginResponse
} from "../../../../server/types/request/login";
import { ErrorResponse } from "../../../../server/types/request";
import { AxiosError } from "axios";
import { ErrorObject } from "../../../../server/validation/validation-base";

export const LoginContainter = () => {
	const history = useHistory();
	const [error, setError] = React.useState<string>();
	const [errors, setErrors] = React.useState<ErrorObject>();
	const onLogin = React.useCallback((mail: string, password: string) => {
		const request: LoginRequest = { mail, password };
		post<LoginResponse>("/login", request)
			.then(response => {
				if (response.data.account) {
					history.push("/");
					setError(undefined);
					setErrors(undefined);
				}
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				setError(error.response.data.message);
				setErrors(error.response.data.errors);
			});
		// TODO:login時の挙動を実装。
	}, []);
	return <LoginPage onLogin={onLogin} error={error} errors={errors} />;
};
