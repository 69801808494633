import { RespondentWithAgreement } from "../model/respondent";
import { replacePlaceholder } from "./common";

export const makeBaseRespondentUrl = () => {
	return `${process.env.APP_URL}/A`;
};

export const makeBaseRedirectUrl = () => {
	return `${process.env.APP_URL}/R?id=[[[モニターID]]]&sid=[[[調査ID]]]`;
};

export const makeMuscatAgreementUrl = (monitorId: string) => {
	const enqueteId = "ni13c4rxus"; // 固定。
	return `${process.env.MUSCAT}/enquete/${enqueteId}?monitors_id=${monitorId}&survey_id=${enqueteId}`;
};

export const makeMuscatEnqueteUrl = (
	enqueteId: string,
	surveyId: string,
	urlParam: string,
	respondent: RespondentWithAgreement
) => {
	const param = replacePlaceholder(urlParam, respondent);
	return encodeURI(
		`${process.env.MUSCAT}/enquete/${enqueteId}?monitors_id=${
			respondent.respondentId
		}&survey_id=${surveyId}${param ? `&${param}` : ""}`
	);
};

// muscatデフォルトの機能のpreviewを使用する。
export const makeMuscatPreviewUrl = (enqueteId: string) => {
	return `${process.env.MUSCAT}/preview/${enqueteId}?logic=0`;
};

export const makeDebugEnqueteUrl = (enqueteId: string) => {
	return `${process.env.APP_URL}/redirect.html?enqueteId=${enqueteId}`;
	// return `${process.env.MUSCAT}/enquete/${enqueteId}?monitors_id=test`;
};
