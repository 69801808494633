import * as React from "react";
import { Chat } from "../../../../../server/model/chat";
import { Button, Card, Col, Row } from "react-bootstrap";
import { RespondentWithRealId } from "../../../../../server/model/respondent";
import { FilterColumn, FilterTable, FilterTableFunc } from "lu-component";
import { respondentLabels } from "../../../../../server/label/respondent";
import { NewChatAdmin } from "../../../../../server/types/request/chat-admin";
import { SendForm } from "../../parts/chat/send-form";
import { ChatHistory } from "../../parts/chat/chat-history";

export type ChatAdminPageProps = {
	respondents: RespondentWithRealId[];
	chats: Chat[];
	chatText: { [key: string]: string };
	onExpandChatText: (respondentIds: string[]) => void;
	onChangeChatText: (respondentId: string, value: string) => void;
	onSend: (chats: NewChatAdmin[]) => void;
	onChatDelete: (id: string) => void;
	expandText: string;
	onChangeExpandText: (value: string) => void;
};

export const ChatAdminPage = React.memo((props: ChatAdminPageProps) => {
	const {
		respondents,
		chats,
		onChatDelete,
		onSend,
		chatText,
		expandText,
		onExpandChatText,
		onChangeChatText,
		onChangeExpandText
	} = props;
	const chatMap = React.useMemo<Map<string, Chat[]>>(() => {
		const m = new Map<string, Chat[]>();
		for (const chat of chats) {
			const tmp = m.get(chat.respondentId) || [];
			tmp.unshift(chat);
			m.set(chat.respondentId, tmp);
		}
		return m;
	}, [chats]);
	const columns = React.useMemo(() => {
		const columns: FilterColumn[] = [
			{
				header: respondentLabels._id,
				dataKey: "respondentId",
				filterType: "text"
			},
			{
				header: respondentLabels.name,
				dataKey: "name",
				filterType: "text"
			},
			{
				header: respondentLabels.mail,
				dataKey: "mail",
				filterType: "text"
			},
			{
				header: respondentLabels.mobileTel,
				dataKey: "mobileTel",
				filterType: "text"
			}
		];
		if (respondents.length) {
			for (const key of Object.keys(respondents[0].otherInfo)) {
				columns.push({ header: key, dataKey: key, filterType: "text" });
			}
		}
		const max = Array.from(chatMap.entries()).reduce((a, [, cs]) => {
			const size = cs.length;
			return a > size ? a : size;
		}, 0);
		columns.push({
			header: "やりとり",
			dataKey: "yaritori0"
		});
		for (let i = 0; i < max; i++) {
			columns.push({
				//header: "やりとり" + (i + 1),
				header: "",
				dataKey: "yaritori" + (i + 1)
			});
		}
		return columns;
	}, [respondents, chatMap]);
	const ref = React.createRef<FilterTableFunc>();
	const onSendAll = React.useCallback(() => {
		if (!ref.current) return;
		const tmp: NewChatAdmin[] = [];
		for (const respondent of ref.current.getDisplayData()) {
			const id = respondent._id.toString();
			if (id in chatText && !!chatText[id]) {
				tmp.push({
					fromAdmin: true,
					respondentId: id,
					message: chatText[id]
				});
			}
		}
		if (tmp.length === 0) {
			return alert("メッセージが1件もありません。");
		}
		onSend(tmp);
	}, [ref, onSend, chatText]);
	const onSendOne = React.useCallback(
		(respondentId: string, value: string) => {
			onSend([{ respondentId, fromAdmin: true, message: value }]);
		},
		[onSend]
	);
	const targetRespondents = React.useMemo(() => {
		const tmp = respondents.map(resp => {
			const { _id, otherInfo, ...other } = resp;
			const chatItems = chatMap.get(_id) || [];
			const chatParam: { [key: string]: string | JSX.Element } = {};
			for (let i = 0; i < chatItems.length; i++) {
				chatParam["yaritori" + (i + 1)] = (
					<ChatHistory
						key={resp._id.toString() + "yaritori" + (i + 1)}
						{...chatItems[i]}
						onChatDelete={onChatDelete}
					/>
				);
			}
			chatParam["yaritori0"] = (
				<SendForm
					key={resp._id.toString() + "yaritori-" + "yaritori0"}
					onChange={onChangeChatText}
					onSend={onSendOne}
					respondentId={_id}
					value={chatText[_id] || ""}
				/>
			);
			return {
				...other,
				_id: _id.toString(),
				...otherInfo,
				...chatParam
			};
		});
		return tmp;
	}, [respondents, chatText, onChangeChatText, chatMap, onChatDelete]);
	const onButtonClick = React.useCallback(() => {
		if (!ref.current) {
			return;
		}
		const respondentsIds: string[] = ref.current
			.getDisplayData()
			.map(r => r._id.toString());
		onExpandChatText(respondentsIds);
	}, [ref, onExpandChatText]);
	return (
		<Row>
			<Col md={12}>
				<div className={"h3"}>■ チャット管理</div>
			</Col>
			<Col className="m-3">
				<Card style={{ width: "400px" }}>
					<Card.Header>表示されている対象者にまとめて入力</Card.Header>
					<Card.Body>
						<Card.Text>
							<textarea
								onChange={e => {
									onChangeExpandText(e.target.value);
								}}
								style={{
									width: "100%",
									height: "100%",
									border: "0px",
									backgroundColor: "#eee"
								}}
								value={expandText}
							/>
						</Card.Text>
						<Button
							style={{ margin: "10px" }}
							variant="outline-success"
							onClick={() => onButtonClick()}
						>
							展開
						</Button>
						<Button variant="primary" onClick={onSendAll}>
							一括送信
						</Button>
					</Card.Body>
				</Card>
				<FilterTable
					columns={columns}
					data={targetRespondents as { [key: string]: string }[]}
					ref={ref}
				/>
			</Col>
		</Row>
	);
});
