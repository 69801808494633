import * as React from "react";
import { Column, FormInputGroups } from "lu-component";
import { Reminder, SendTypeOption } from "../../../../../server/model/reminder";
import { reminderLabels } from "../../../../../server/label/reminder";
import { ErrorObject } from "../../../../../server/validation/validation-base";

const columns: Column[][] = [
	[
		{
			label: reminderLabels.sendDatetime,
			name: "sendDatetime",
			type: "datetime",
			col: 5
		},
		{
			label: reminderLabels.sendType,
			name: "sendType",
			type: "select",
			options: SendTypeOption,
			col: 5
		}
	],
	[
		{
			label: reminderLabels.title,
			name: "title",
			type: "text"
		}
	],
	[
		{
			label: "文字数カウント",
			name: "test",
			type: "custom",
			isShow: (data: Reminder) => data.sendType !== "mail",
			children: (data: Reminder) => {
				const textLength = (data.title + "\n" + data.message).replace(
					/\[\[\[([^\\[\]]*)\]\]\]/g,
					""
				).length;
				return (
					<>
						<div>
							660文字以内で入力してください。(現在の最低文字数：{textLength}）
						</div>
						<div style={{ color: "red" }}>※ タグはカウントしていません。</div>
					</>
				);
			}
		}
	],
	[
		{
			label: reminderLabels.message,
			name: "message",
			type: "textarea"
		}
	]
];

export type ReminderFormProps = {
	reminder?: Reminder;
	errors?: ErrorObject;
	onChange: (name: keyof Reminder, value: string) => void;
};
export const ReminderForm = React.memo((props: ReminderFormProps) => {
	const { reminder, onChange, errors } = props;
	return (
		<FormInputGroups
			columns={columns}
			data={reminder}
			onChange={onChange}
			errors={errors}
		/>
	);
});
