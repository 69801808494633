import * as React from "react";
import { MyPageLogin } from "../../components/pages/mypage-login";
import { post, makeError, get } from "../../lib/request";
import { reducer, initState } from "../../reducers/mypage-login";
import { Loading } from "lu-component";
import { Caution } from "../../components/parts/caution";
import {
	PostMyPageLoginResponse,
	GetMyPageLoginResponse
} from "../../../../server/types/request/mypage-login";
import { useHistory } from "react-router";
import { Info } from "../../components/parts/info";
import { isSamsungBrowser } from "../../lib/common";

export const MyPageLoginContainer = () => {
	const history = useHistory();
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { loading, info, job, password } = state;
	const [browserMessage, setBrowserMessage] = React.useState<string>("");
	React.useEffect(() => {
		if (isSamsungBrowser(navigator.userAgent)) {
			setBrowserMessage(
				"このブラウザでは正常にアンケートに回答できない可能性があります。もし正常にアンケートに回答ができなければ、chromeやfirefox等のブラウザをインストールいただき、そちらでご回答ください"
			);
		}
		get<GetMyPageLoginResponse>("/respondent-job")
			.then(response => {
				dispatch({ type: "setInit", payload: response.data });
			})
			.catch(err => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(err, true)
				});
			});
	}, []);
	const onLogin = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<PostMyPageLoginResponse>("/mypage-login", { password })
			.then(response => {
				location.href = response.data.redirectPage;
			})
			.catch(err => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(err, true)
				});
			});
	}, [password]);
	const onChange = React.useCallback((password: string) => {
		dispatch({ type: "changePassword", payload: password });
	}, []);
	const onClose = React.useCallback(() => {
		dispatch({
			type: "changeMessageInfo",
			payload: { isSuccess: true, message: "" }
		});
	}, []);
	return (
		<>
			{loading && <Loading loading={loading} />}
			{!info.isSuccess && (
				<Info title={"エラー"} show message={info.message} onClose={onClose} />
			)}
			<MyPageLogin
				job={job}
				errors={info.errors}
				onLogin={onLogin}
				onChange={onChange}
				password={password}
				message={browserMessage}
			/>
		</>
	);
};
