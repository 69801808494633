import { Labels } from ".";
import { Reminder } from "../model/reminder";

export const reminderLabels: Labels<Reminder> = {
	_id: "督促",
	surveyId: "調査",
	title: "タイトル",
	message: "内容",
	sendDatetime: "送信日時",
	sendType: "送信方法"
};
