import { MyPageLoginAction } from "../types";
import { MessageInfo } from "../types/info";
import { Job } from "../../../server/model/job";

export type State = {
	job: Job;
	password: string;
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	job: undefined,
	password: "",
	loading: true,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: MyPageLoginAction): State => {
	switch (action.type) {
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
		case "setInit":
			return {
				...state,
				...action.payload,
				loading: false,
				info: { message: "", isSuccess: true }
			};
		case "changePassword":
			return {
				...state,
				password: action.payload
			};
		default:
			return state;
	}
};
