import * as React from "react";
import { reducer, initState } from "../../reducers/chat-admin";
import { get, remove, post, makeError } from "../../lib/request";
import {
	GetChatAmindResponse,
	CreateChatsResponse,
	NewChatAdmin
} from "../../../../server/types/request/chat-admin";
import { useParams } from "react-router";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../../../server/types/request";
import { ChatAdminPage } from "../../components/pages/chat-admin";
import { InfoMessage } from "lu-component";

export const ChatAdminContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);
	const { info, chats, respondents, chatText, expandText } = state;
	const { jobId } = useParams();
	React.useEffect(() => {
		get<GetChatAmindResponse>(`/chat-admins/${jobId}`)
			.then(response => {
				dispatch({ type: "setRespondentsAndChats", payload: response.data });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error)
				});
			});
	}, []);
	const onSend = React.useCallback((chats: NewChatAdmin[]) => {
		post<CreateChatsResponse>(`/chat-admin/${jobId}`, { chats })
			.then(response => {
				dispatch({ type: "addChats", payload: response.data.chats });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	const onDelete = React.useCallback((id: string) => {
		remove("/chat-admin", id)
			.then(() => {
				dispatch({ type: "deleteChat", payload: id });
			})
			.catch((error: AxiosError<ErrorResponse>) => {
				dispatch({
					type: "changeMessageInfo",
					payload: makeError(error, true)
				});
			});
	}, []);
	const onChangeChatText = React.useCallback(
		(respondentId: string, value: string) => {
			dispatch({ type: "changeChatText", payload: { respondentId, value } });
		},
		[]
	);
	const onExpandChatText = React.useCallback((respondentIds: string[]) => {
		dispatch({ type: "expandChatText", payload: respondentIds });
	}, []);
	const onChangeExpandText = React.useCallback((value: string) => {
		dispatch({ type: "changeExpandText", payload: value });
	}, []);
	return (
		<>
			<InfoMessage info={info} />
			<ChatAdminPage
				respondents={respondents}
				chats={chats}
				chatText={chatText}
				expandText={expandText}
				onChangeExpandText={onChangeExpandText}
				onChangeChatText={onChangeChatText}
				onExpandChatText={onExpandChatText}
				onChatDelete={onDelete}
				onSend={onSend}
			/>
		</>
	);
};
