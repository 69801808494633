import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ErrorPage } from "../components/pages/error";
import { LoginContainter } from "../containers/login";
import { WithAuth } from "../containers/auth";
import { AccountContainter } from "../containers/account";
import { JobContainter } from "../containers/job";
import { LogoutContainter } from "../containers/logout";
import { JobEnqueteContainter } from "../containers/job-enquete";
import { SurveyContainer } from "../containers/survey";
import { RespondentContainer } from "../containers/respondent";
import { EnqueteContainer } from "../containers/enquete";
import { MyPageContainer } from "../containers/mypage";
import { ChatAdminContainer } from "../containers/chat-admin";
import { WithRespondentAuth } from "../containers/respondent-auth";
import { ChatContainer } from "../containers/chat";
import { AnsweredContainer } from "../containers/answered";
import { AgreementContainer } from "../containers/agreement";
import { MyPageLoginContainer } from "../containers/mypage-login";
import { MyPageErrorPage } from "../components/pages/mypage-error";

export const Routes = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/login" exact>
					<LoginContainter />
				</Route>
				<Route path="/logout" exact>
					<LogoutContainter />
				</Route>
				<Route path="/" exact>
					<WithAuth>
						<JobContainter />
					</WithAuth>
				</Route>
				<Route path="/account" exact>
					<WithAuth>
						<AccountContainter />
					</WithAuth>
				</Route>
				<Route path="/job-enquete/:jobId" exact>
					<WithAuth>
						<JobEnqueteContainter />
					</WithAuth>
				</Route>
				<Route path="/survey/:jobId" exact>
					<WithAuth>
						<SurveyContainer />
					</WithAuth>
				</Route>
				<Route path="/enquete/:jobId/:jobEnqueteId" exact>
					<WithAuth>
						<EnqueteContainer />
					</WithAuth>
				</Route>
				<Route path="/respondent/:jobId" exact>
					<WithAuth>
						<RespondentContainer />
					</WithAuth>
				</Route>
				<Route path="/chat-admin/:jobId" exact>
					<WithAuth>
						<ChatAdminContainer />
					</WithAuth>
				</Route>
				{/*
				<Route path="/agreement" exact>
					<WithRespondentAuth>
						<AgreementContainer />
					</WithRespondentAuth>
				</Route>
				 */}
				<Route path="/mypage" exact>
					<WithRespondentAuth>
						<MyPageContainer />
					</WithRespondentAuth>
				</Route>
				<Route path="/mypage-login" exact>
					<MyPageLoginContainer />
				</Route>
				<Route path="/chat" exact>
					<WithRespondentAuth>
						<ChatContainer />
					</WithRespondentAuth>
				</Route>
				<Route path="/answered" exact>
					<WithRespondentAuth>
						<AnsweredContainer />
					</WithRespondentAuth>
				</Route>
				<Route path="/close" exact>
					<MyPageErrorPage
						title={"調査終了"}
						message={
							<>
								調査は終了いたしました。
								<br />
								今後ともよろしくお願いいたします。
							</>
						}
					/>
				</Route>
				<Route path="/stopping" exact>
					<MyPageErrorPage
						title={"調査停止中"}
						message={
							<>
								調査が停止しています。
								<br />
								しばらく時間をおいてからアクセスをお願いします。
							</>
						}
					/>
				</Route>
				<Route path="/permission-denied">
					<ErrorPage text="Permission Denied" />
				</Route>
				<Route path="/not-supported">
					<MyPageErrorPage
						title={"エラー"}
						message={
							<>
								お使いのブラウザではこのアンケートはご回答になれません。
								<br />
								お手数をおかけしますがお使いのAndroid端末にGoogle
								Chromeをインストールの上、Google
								Chromeにてアクセスいただくか、パソコンからご回答ください
							</>
						}
					/>
					<Route path="/not-found">
						<ErrorPage text="Not Found" />
					</Route>
				</Route>
				<Route>
					<ErrorPage text="Not Found" />
				</Route>
			</Switch>
		</BrowserRouter>
	);
};
