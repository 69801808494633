import * as React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { post } from "../../lib/request";
import { RespondentWithAgreement } from "../../../../server/model/respondent";
import { GetRespondentAuthResponse } from "../../../../server/types/request/respondent-auth";
import { makeMuscatAgreementUrl } from "../../../../server/lib/url";

export type WithRespondentAuthProps = {
	children: React.ReactChild;
};
export const WithRespondentAuth = React.memo(
	(props: WithRespondentAuthProps) => {
		const { children } = props;
		const [requested, setRequested] = React.useState<boolean>(false);
		// const location = useLocation();
		const [respondent, setRespondent] = React.useState<
			RespondentWithAgreement
		>();
		React.useEffect(() => {
			post<GetRespondentAuthResponse>("/auth/respondent")
				.then(response => {
					if (
						response.data.respondent &&
						response.data.respondent.agreement.length === 0
					) {
						location.href = makeMuscatAgreementUrl(
							response.data.respondent._id
						);
						return;
					}
					setRespondent(response.data.respondent);
					setRequested(true);
				})
				.catch(() => {
					setRequested(true);
				});
		}, []);
		console.log("------");
		console.log(respondent);
		return (
			<>
				{requested &&
					(respondent ? (
						respondent.agreement.length === 0 ? (
							<></>
						) : (
							children
						)
					) : (
						// 認証がない場合はnot foundページへ。
						<Redirect to={"/not-found"} />
					))}
			</>
		);
	}
);
