import * as React from "react";
import { Option, FormInputGroups, Column, ConfirmButton } from "lu-component";
import { Survey } from "../../../../../server/model/survey";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { Reminder } from "../../../../../server/model/reminder";
import { surveyWithReminderLabels } from "../../../../../server/label/survey";
import { ErrorObject } from "../../../../../server/validation/validation-base";
import { Job } from "../../../../../server/model/job";
import { RemindersForm } from "../reminders-form";

export type SurveyFormProps = {
	job: Job;
	survey?: Survey;
	reminders?: Reminder[];
	jobEnquetes: Option[];
	show: boolean;
	errors?: ErrorObject;
	onClose: () => void;
	onDelete: (id: string) => void;
	onSave: (survey: Survey, reminders: Reminder[]) => void;
};

export const SurveyForm = React.memo((props: SurveyFormProps) => {
	const { job, jobEnquetes, onSave, show, onClose, onDelete, errors } = props;
	const [survey, setSurvey] = React.useState<Survey>({
		jobEnqueteId: undefined,
		name: "",
		urlParam: "",
		note: "",
		startDatetime: "",
		endDatetime: "",
		enableAnswerForClosedSurvey: true
	});
	const [reminders, setReminders] = React.useState<Reminder[]>([]);

	React.useEffect(() => {
		setSurvey(
			props.survey || {
				jobEnqueteId: undefined,
				name: "",
				urlParam: "",
				note: "",
				startDatetime: "",
				endDatetime: "",
				enableAnswerForClosedSurvey: true
			}
		);
	}, [show]);
	React.useEffect(() => {
		setReminders(props.reminders || []);
	}, [props.reminders, show]);

	const onSaveClick = React.useCallback(() => {
		onSave(survey as Survey, reminders);
	}, [onSave, survey, reminders]);
	const columns = React.useMemo<Column<Survey>[][]>(() => {
		return [
			[
				{
					name: "jobEnqueteId",
					label: surveyWithReminderLabels.jobEnqueteId,
					type: "select",
					options: jobEnquetes,
					col: 6
				},
				{
					name: "name",
					type: "text",
					label: surveyWithReminderLabels.name,
					col: 6
				}
			],
			[
				{
					name: "urlParam",
					label: surveyWithReminderLabels.urlParam,
					type: "text",
					col: 6,
					placeholder: "ex.)rote=[[[ローテーション]]]&day=1"
				}
			],
			[
				{
					name: "startDatetime",
					label: surveyWithReminderLabels.startDatetime,
					type: "datetime"
				},
				{
					name: "endDatetime",
					label: surveyWithReminderLabels.endDatetime,
					type: "datetime"
				}
			],
			[
				{ name: "note", label: surveyWithReminderLabels.note, type: "textarea" }
			],
			[
				{
					name: "enableAnswerForClosedSurvey",
					label: surveyWithReminderLabels.enableAnswerForClosedSurvey,
					type: "checkbox"
				}
			]
		];
	}, [jobEnquetes]);
	const onChangeSurvey = React.useCallback(
		(name: keyof Survey, value: string | boolean) => {
			const changeSurvey = { ...survey, [name]: value };
			if (name === "jobEnqueteId" && value) {
				const target = jobEnquetes.find(op => op.value === value);
				if (target) changeSurvey.name = target.label;
			}
			if (name === "startDatetime" && !changeSurvey.endDatetime) {
				changeSurvey.endDatetime = value.toString();
			}
			setSurvey(changeSurvey);
		},
		[survey, jobEnquetes]
	);
	const onDeleteClick = React.useCallback(() => {
		onClose();
		if (survey._id) onDelete(survey._id.toString());
	}, [survey, onClose]);
	const surveyErrors = React.useMemo(() => {
		if (errors) {
			if ("survey" in errors) {
				return errors["survey"] as ErrorObject;
			}
			return errors;
		}
		return undefined;
	}, [errors]);
	return (
		<Modal size="lg" show={show} onHide={onClose}>
			<Modal.Header closeButton>アンケート・督促設定</Modal.Header>
			<Modal.Body>
				<FormInputGroups
					errors={surveyErrors}
					columns={columns}
					data={survey}
					onChange={onChangeSurvey}
				/>
				<RemindersForm
					label={"督促"}
					target={survey}
					job={job}
					errors={errors}
					reminders={reminders}
					onChange={setReminders}
				/>
			</Modal.Body>
			<Modal.Footer style={{ textAlign: "right" }}>
				<Button variant={"outline-primary"} onClick={onSaveClick}>
					保存
				</Button>
				{survey._id && (
					<ConfirmButton
						title={"予定の削除"}
						buttonText={"削除"}
						body={"削除をすると元には戻せません。よろしいですか？"}
						onOk={onDeleteClick}
					/>
				)}
				<Button variant={"outline-secondary"} onClick={onClose}>
					キャンセル
				</Button>
			</Modal.Footer>
		</Modal>
	);
});
