import * as React from "react";
import { Redirect } from "react-router-dom";
import { post } from "../../lib/request";
import { LoginResponse } from "../../../../server/types/request/login";
import { NavigationBar, NavigationItem } from "lu-component";
import { useHistory, useLocation, useParams } from "react-router";
import { AccountWithoutPassowrd } from "../../../../server/model/account";

export type WithAuthProps = {
	children: React.ReactChild;
};
export const WithAuth = React.memo((props: WithAuthProps) => {
	const { children } = props;
	const [requested, setRequested] = React.useState<boolean>(false);
	const [account, setAccount] = React.useState<AccountWithoutPassowrd>();
	const history = useHistory();
	const location = useLocation();
	const { jobId } = useParams();
	React.useEffect(() => {
		post<LoginResponse>("/auth")
			.then(response => {
				if (response.data.account) {
					setAccount(response.data.account);
				}
				setRequested(true);
			})
			.catch(_ => {
				setRequested(true);
			});
	}, []);
	const navItems = React.useMemo<{
		right: NavigationItem[];
		left: NavigationItem[];
	}>(() => {
		if (!account) {
			return { right: [], left: [] };
		}
		const left = (account.isAdmin
			? [
					{
						type: "link",
						label: "アカウント管理",
						action: () => history.push("/account"),
						active: location.pathname === "/account"
					}
			  ]
			: []
		).concat([
			{
				type: "link",
				label: "ジョブ一覧",
				action: () => history.push("/"),
				active: location.pathname === "/"
			}
		]) as NavigationItem[];
		if (jobId) {
			left.push({
				id: "job-settings",
				type: "dropdown",
				label: "ジョブ設定",
				items: [
					{
						type: "link",
						label: "アンケート一覧",
						action: () => history.push(`/job-enquete/${jobId}`)
					},
					{
						type: "link",
						label: "スケジュール登録",
						action: () => history.push(`/survey/${jobId}`)
					},
					{
						type: "link",
						label: "対象者設定",
						action: () => history.push(`/respondent/${jobId}`)
					},
					{
						type: "link",
						label: "チャット管理",
						action: () => history.push(`/chat-admin/${jobId}`)
					}
				]
			});
		}
		return {
			left,
			right: [
				{
					type: "text",
					label: account.name
				},
				{
					type: "link",
					label: "ログアウト",
					action: () => history.push("/logout")
				}
			]
		};
	}, [account, location, history, jobId]);
	return (
		<>
			{/** TODO権限をチェックしてリダイレクトを指定 */}
			{requested &&
				(account ? (
					<>
						<NavigationBar
							blandName={"NI-HUT-DX"}
							rightNavItems={navItems.right}
							leftNavItems={navItems.left}
						/>
						<div className={"m-3"}>{children}</div>
					</>
				) : (
					// 認証がない場合はloginへ遷移
					<Redirect to={"/login"} />
				))}
		</>
	);
});
